import L from "leaflet";
import "leaflet-draw";
import { createControlComponent } from "@react-leaflet/core";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

const GeomanControl = L.Control.extend({
    initialize(options) {
        L.PM.setOptIn(true);
        L.setOptions(this, options);
    },

    addTo(map) {
        // This should never happen, but it's better than crashing the page
        if (!map.pm) return;

        map.pm.addControls({
            cutPolygon: false,
            drawCircle: false,
            drawCircleMarker: false, // same as drawCircle except that it is in pixels, i.e. remains same size independent from zoom level
            drawMarker: false,
            drawPolygon: false,
            drawPolyline: false,
            drawRectangle: false,
            drawText: false,
            editMode: false,
            oneBlock: true,
            position: "bottomright",
            rotateMode: true,
        });
    },
});

const createControl = (props) => {
    return new GeomanControl(props);
};

export const Geoman = createControlComponent(createControl);
