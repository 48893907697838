import { fetchWithMsal, handleResponse } from "../utils";

export const elevationService = {
    get,
};

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/elevation`;

function get(coordinates) {
    const pathElements = [];
    coordinates.forEach((coordinate) => {
        pathElements.push(`${coordinate.lat},${coordinate.lng}`);
    });

    if (pathElements.length > 512) {
        console.error(
            `elevationService.get: you requested elevation for ${pathElements.length} coordinates but only 512 are allowed at most.`,
        );
        return Promise.resolve(null);
    }

    const path = pathElements.join("|");
    const parameters = `${path}`;
    // const parameters = `${path}/${25 * pathElements.length}`; // divides the path into an ordered set of equidistant points along the path
    return fetchWithMsal(`${baseUrl}/${parameters}`, {
        method: "GET",
    })
        .then(handleResponse)
        .then((response) => {
            if ("OK" !== response.status) {
                console.error(
                    `elevationService.get: ${response.status}`,
                    response.error_message,
                );
                return null;
            }

            return response.results;
        })
        .catch((error) => {
            console.error("elevationService.get", error, path);
            return null;
        });
}
