import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";

// for every legacy layer (intersect endpoint)
export function FilterLineFormFieldLegacy({
    layerName,
    availableKeys,
    defaults,
    handleUpdate,
    submit,
}) {
    const [condition, setCondition] = useState(defaults.condition);
    const [keysNextLevel, setKeysNextLevel] = useState(
        availableKeys[defaults.key],
    );
    const [key, setKey] = useState(defaults.key);
    const [keyNextLevel, setKeyNextLevel] = useState(defaults.keyNextLevel);
    const [value, setValue] = useState(defaults.value);

    useEffect(() => {
        setCondition(defaults.condition);
        setKeysNextLevel(availableKeys[defaults.key]);
        setKey(defaults.key);
        setKeyNextLevel(defaults.keyNextLevel);
        setValue(defaults.value);
    }, [defaults, layerName]);

    // this updates the state variables based on the assumption that only one form value changes at a time
    const update = (values) => {
        let localCondition;
        let localKey;
        let localKeyNextLevel;
        let localValue;

        if ("condition" in values) {
            localCondition = values.condition;
            setCondition(localCondition);
        } else {
            localCondition = condition;
        }

        if ("key" in values) {
            localCondition = "";
            localKey = values.key;
            localKeyNextLevel = "";
            localValue = "";
            setCondition(localCondition);
            setKey(localKey);
            setKeyNextLevel(localKeyNextLevel);
            setKeysNextLevel(availableKeys?.[localKey] || []);
            setValue(localValue);
        } else {
            localKey = key;
        }

        if ("keyNextLevel" in values) {
            localKeyNextLevel = values.keyNextLevel;
            setKeyNextLevel(localKeyNextLevel);
        } else {
            localKeyNextLevel = keyNextLevel;
        }

        if ("value" in values) {
            localValue = values.value;
            setValue(localValue);
        } else {
            localValue = value;
        }

        handleUpdate({
            condition: localCondition,
            key: localKey,
            keyNextLevel: localKeyNextLevel,
            value: localValue,
        });
    };

    const renderForm = () => {
        if (undefined !== defaults.options) {
            return (
                <Form.Select
                    onChange={(event) => {
                        const localCondition = "eq";
                        const localKey = "nom";
                        const localValue = event.target.value;
                        setCondition(localCondition);
                        setKey(localKey);
                        setValue(localValue);
                        handleUpdate({
                            condition: localCondition,
                            key: localKey,
                            keyNextLevel: "",
                            value: localValue,
                        });
                    }}
                    size="sm"
                    value={value}
                >
                    <option key="" value="" />
                    {defaults.options.map((localKey) => (
                        <option key={localKey} value={localKey}>
                            {localKey}
                        </option>
                    ))}
                </Form.Select>
            );
        }

        return (
            <>
                <Form.Select
                    name="key"
                    onChange={(event) => {
                        update({
                            key: event.target.value,
                        });
                    }}
                    size="sm"
                    value={key}
                >
                    <option key="" value="" />
                    {Object.keys(availableKeys).map((localKey) => (
                        <option key={localKey} value={localKey}>
                            {localKey}
                        </option>
                    ))}
                </Form.Select>
                {undefined !== keysNextLevel && 0 < keysNextLevel.length && (
                    <Form.Select
                        className="mt-1"
                        name="keyNextLevel"
                        onChange={(event) => {
                            update({
                                keyNextLevel: event.target.value,
                            });
                        }}
                        size="sm"
                        value={keyNextLevel}
                    >
                        <option key="" value="" />
                        {keysNextLevel.map((localKey) => (
                            <option key={localKey} value={localKey}>
                                {localKey}
                            </option>
                        ))}
                    </Form.Select>
                )}
                <Form.Select
                    className="mt-1"
                    name="condition"
                    onChange={(event) =>
                        update({
                            condition: event.target.value,
                        })
                    }
                    size="sm"
                    value={condition}
                >
                    {[
                        {
                            key: "",
                            name: "",
                        },
                        {
                            key: "eq",
                            name: "=",
                        },
                        {
                            key: "gte",
                            name: ">=",
                        },
                        {
                            key: "lte",
                            name: "<=",
                        },
                        {
                            key: "incl",
                            name: "⊃",
                        },
                        {
                            key: "not",
                            name: "≠",
                        },
                    ].map((option) => (
                        <option key={option.key} value={option.key}>
                            {option.name}
                        </option>
                    ))}
                </Form.Select>
                <Form.Control
                    className="mt-1"
                    name="value"
                    onChange={(event) => update({ value: event.target.value })}
                    onKeyDown={(event) => {
                        if ("Enter" === event.key) {
                            event.preventDefault();
                            submit();
                        }
                    }}
                    size="sm"
                    type="text"
                    value={value}
                />
            </>
        );
    };

    return <Form.Group className="mb-3">{renderForm()}</Form.Group>;
}
