import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import { alertService, alertType } from "../services";

Alert.propTypes = {
    id: PropTypes.string,
    fade: PropTypes.bool,
};

Alert.defaultProps = {
    id: "default-alert",
    fade: true,
};

export function MapAlert({ id, fade }) {
    const mounted = useRef(false);
    const [alerts, setAlerts] = useState([]);

    useEffect(() => {
        mounted.current = true;

        // subscribe to new alert notifications
        const subscription = alertService.onAlert(id).subscribe((alert) => {
            // clear alerts when an empty alert is received
            if (!alert.message) {
                setAlerts(alerts);
            } else {
                // add alert to array with unique id
                alert.itemId = Math.random();
                setAlerts((alerts) => [...alerts, alert]);

                // auto close alert if required
                if (alert.autoClose) {
                    setTimeout(() => removeAlert(alert), 3000);
                }
            }
        });

        // clean up function that runs when the component unmounts
        return () => {
            mounted.current = false;

            // unsubscribe to avoid memory leaks
            subscription.unsubscribe();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function removeAlert(alert) {
        if (!mounted.current) return;

        if (fade) {
            // fade out alert
            setAlerts((alerts) =>
                alerts.map((x) =>
                    x.itemId === alert.itemId ? { ...x, fade: true } : x,
                ),
            );

            // remove alert after faded out
            setTimeout(() => {
                setAlerts((alerts) =>
                    alerts.filter((x) => x.itemId !== alert.itemId),
                );
            }, 250);
        } else {
            // remove alert
            setAlerts((alerts) =>
                alerts.filter((x) => x.itemId !== alert.itemId),
            );
        }
    }

    function cssClasses(alert) {
        if (!alert) return;

        const classes = ["alert", "alert-dismissible"];

        const alertTypeClass = {
            [alertType.Success]: "alert-success",
            [alertType.Error]: "alert-danger",
            [alertType.Info]: "alert-info",
            [alertType.Warning]: "alert-warning",
        };

        classes.push(alertTypeClass[alert.type]);

        if (alert.fade) {
            classes.push("fade");
        }

        return classes.join(" ");
    }

    if (!alerts.length) return null;

    return (
        <>
            {alerts.map((alert, index) => (
                <div key={index} className={cssClasses(alert)} role="alert">
                    <span dangerouslySetInnerHTML={{ __html: alert.message }} />
                    <button
                        onClick={() => removeAlert(alert)}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                    />
                </div>
            ))}
        </>
    );
}
