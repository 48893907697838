export const MODE_DEFAULT = "DEFAULT";
export const MODE_JBOX_LOCATION = "JBOX_LOCATION";
export const MODE_KEY_ACCOUNTS = "GRAND_COMPTE";
export const MODE_PERMITTING = "PERMITTING";
export const MODE_PROSPECTION = "PROSPECTION";

export const availableModes = {
    [MODE_DEFAULT]: {
        activeLayers: {
            // order is important and will be reflected on the app
            FI: [],
            FR: [
                "Plots",
                "HTA Lines",
                "Municipalities",
                "Substations v2", // general
            ],
            SE: [
                "Substations", // general
            ],
            TX: ["Substations", "Substations Zones"],
        },
        label: "Default",
        layerSubstationZonesActive: true,
        unhiddenLayers: {
            FR: [],
            FI: [],
            SE: [],
            JP: [],
            TX: [],
            IT: [],
        },
    },
    [MODE_JBOX_LOCATION]: {
        activeLayers: {
            // order is important and will be reflected on the app
            FR: [
                "Substations v2", // general
                "HTA Lines",
                "Natural Gas Network",
                "BT Lines",
                "HTB Lines",
                "Municipalities",
            ],
        },
        label: "JBox location",
        layerSubstationZonesActive: false,
        unhiddenLayers: {
            FR: [
                "Substations v2", // general
                "HTA Lines",
                "Natural Gas Network",
                "BT Lines",
                "HTB Lines",
                "Municipalities",
            ],
            FI: [],
            SE: [],
            JP: [],
            TX: [],
            IT: [],
        },
    },
    [MODE_KEY_ACCOUNTS]: {
        activeLayers: {
            // order is important and will be reflected on the app
            FR: [
                "Plots",
                "HTA Lines",
                "Municipalities",
                "Substations v2", // general
            ],
        },
        label: "Key Accounts",
        layerSubstationZonesActive: true,
        unhiddenLayers: {
            FR: ["Plots", "HTA Lines", "Municipalities", "Substations v2"],
            FI: [],
            SE: [],
            JP: [],
            TX: [],
            IT: [],
        },
    },
    [MODE_PERMITTING]: {
        activeLayers: {
            // order is important and will be reflected on the app
            FR: [
                "Substations v2",
                "ABF Zones",
                "ABF Zones (buffer 500m)",
                "ABF Zones v2",
                "Classified Sites",
                "HTA Lines",
                "JBox and IECharge",
                "Municipalities",
                "Natura 2000",
                "UNESCO Zones",
            ],
        },
        label: "Permitting",
        layerSubstationZonesActive: true,
        unhiddenLayers: {
            FR: [
                "Substations v2",
                "ABF Zones",
                "ABF Zones (buffer 500m)",
                "ABF Zones v2",
                "Classified Sites",
                "HTA Lines",
                "JBox and IECharge",
                "Municipalities",
                "Natura 2000",
                "UNESCO Zones",
            ],
            FI: [],
            SE: [],
            JP: [],
            TX: [],
            IT: [],
        },
    },
    [MODE_PROSPECTION]: {
        activeLayers: {
            // order is important and will be reflected on the app
            FI: [
                "Substations", // general
                "HTA Lines 10k Savon (buffer 50m)", // general
                "Protected Zones",
                "Flood Zones",
                "Underground Cavities",
                "Substations Buffer",
                "Municipalities",
            ],
            FR: [
                "Substations v2", // general
                "JBox and IECharge",
                "Project Plots",
                "HTA Lines",
                "Plots",
                "Municipalities",
            ],
            SE: [
                "Substations", // general
                "Substations Zones",
                "Plots",
                "Municipalities",
                "Wetlands",
                "Protected Zones",
            ],
        },
        label: "Prospection",
        layerSubstationZonesActive: false,
        unhiddenLayers: {
            FR: [
                "Substations v2", // general
                "JBox and IECharge",
                "Project Plots",
                "HTA Lines",
                "Plots",
                "Municipalities",
            ],
            FI: [
                "Substations", // general
                "HTA Lines 10k Savon (buffer 50m)", // general
                "Protected Zones",
                "Flood Zones",
                "Underground Cavities",
                "Substations Buffer",
                "Municipalities",
            ],
            SE: [
                "Substations", // general
                "Substations Zones",
                "Plots",
                "Municipalities",
                "Wetlands",
                "Protected Zones",
            ],
            JP: [],
            TX: [],
            IT: [],
        },
    },
};
