import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useMap } from "react-leaflet/hooks";
import { useSelector } from "react-redux";
import {
    Pencil as IconEdit,
    Save as IconSave,
    Trash as IconTrash,
} from "react-bootstrap-icons";
import { alertService, operatorService, projectService } from "../services";
import { getCoordinatesForGeometry, inverseCoordinates } from "../utils";

export function Project({ project, removePlot, setProject }) {
    const map = useMap();

    const NAME_NEW = "NEW_PROJECT";
    const SUBMIT_LABEL_NEW = "Create Project";
    const SUBMIT_LABEL_UPDATE = SUBMIT_LABEL_NEW; //'Update';

    const layersTypes = useSelector((state) => state.layers.value.types);
    const userSubstation = useSelector((state) => state.user.value.substation);

    const [isEditName, setIsEditName] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [name, setName] = useState(NAME_NEW);
    const [submitLabel, setSubmitLabel] = useState(SUBMIT_LABEL_NEW);

    // init existing project that has been selected through search
    useEffect(() => {
        if (undefined !== project?.name) {
            setName(project.name);
            setSubmitLabel(SUBMIT_LABEL_UPDATE);
        } else {
            setName(NAME_NEW);
            setSubmitLabel(SUBMIT_LABEL_NEW);
        }

        return () => {
            setName(NAME_NEW);
            setSubmitLabel(SUBMIT_LABEL_NEW);
        };
    }, [project]);

    const handleRename = (e) => {
        setName(e.target.value);
        setProject({
            ...project,
            name: e.target.value,
        });
    };

    const renderEditNameIcon = () => {
        if (false === isEditName) {
            return <IconEdit title="Edit name" />;
        }

        return <IconSave title="Save name" />;
    };

    const showPlot = (plot) => {
        operatorService
            .getElement(layersTypes.plot._id, {
                phrase: [
                    {
                        key: "display_name",
                        value: plot,
                    },
                ],
            })
            .then((item) => {
                if (null !== item) {
                    const coordinates = inverseCoordinates(
                        getCoordinatesForGeometry(item.geometry),
                    );
                    map.flyToBounds(coordinates);
                } else {
                    alertService.warn(
                        `Plot <strong>${plot}</strong> not found`,
                    );
                }
            });
    };

    const plotsCount = project?.plot_ids.length || 0;
    return (
        <Form
            onSubmit={(event) => {
                event.preventDefault();
                setIsSubmitting(true);

                // update
                // if (project !== null && project.name !== undefined) {
                //     // && project.id !== undefined) {
                //     setIsSubmitting(false);
                //     alertService.warn("PROJECT UPDATE TEMPORARILY DISABLED");
                //     return;

                //     // keep in mind that the substation cannot be changed
                //     projectService
                //         .put(project.name, {
                //             name: name,
                //             plot_ids: project.plot_ids,
                //         })
                //         .then(() => {
                //             alertService.success(`Project <strong>${name}</strong> successfully updated.`);
                //         })
                //         .catch(() => {
                //             alertService.error(`Update of project <strong>${name}</strong> failed.`);
                //         })
                //         .finally(() => {
                //             setIsSubmitting(false);
                //         });
                //     // create
                // } else

                // {
                // This is crucial, since no substation code will make Airtable associate ALL substations to a newly created project and cause in return a massive load time on the frontend
                if (null === project.substation_code) {
                    alertService.warn("No substation has been selected");
                    setIsSubmitting(false);
                    return;
                }

                projectService
                    .post({
                        name: name,
                        plot_ids: project.plot_ids,
                        substation_code: project.substation_code,
                    })
                    .then((response) => {
                        setProject(response);
                        alertService.success(
                            `Project <strong>${name}</strong> successfully created.`,
                        );
                    })
                    .catch(() => {
                        alertService.error(
                            `Creation of project <strong>${name}</strong> failed.`,
                        );
                    })
                    .finally(() => {
                        setIsSubmitting(false);
                    });
                // }
            }}
        >
            <div className="input-group">
                <Form.Control
                    className="form-control-lg"
                    disabled={false === isEditName}
                    name="name"
                    onChange={handleRename}
                    onKeyPress={(event) => {
                        if ("Enter" === event.key) {
                            event.preventDefault();
                            setIsEditName(false);
                        }
                    }}
                    type="text"
                    value={name}
                />
                <span
                    className="input-group-text"
                    onClick={() => {
                        setIsEditName(!isEditName);
                    }}
                    role="button"
                >
                    {renderEditNameIcon()}
                </span>
            </div>
            <h5 className="mt-3">
                Substation
                {undefined !== project?.substation_code ? (
                    <span className="badge bg-secondary ms-2">
                        {project.substation_code}
                    </span>
                ) : (
                    <span className="badge bg-warning ms-2">NONE</span>
                )}
            </h5>
            <h5 className="mt-3">
                Selected plots
                <span className="badge bg-secondary ms-2">{plotsCount}</span>
            </h5>
            {0 < plotsCount && (
                <ul>
                    {project.plot_ids.map((plotId, key) => (
                        <li key={key}>
                            <span
                                onClick={(event) => {
                                    event.preventDefault();
                                    showPlot(plotId);
                                }}
                                role="button"
                            >
                                {plotId}
                            </span>
                            <IconTrash
                                className="ms-2"
                                onClick={(event) => {
                                    event.preventDefault();
                                    removePlot(plotId);
                                }}
                                role="button"
                                size={12}
                            />
                        </li>
                    ))}
                </ul>
            )}
            <div className="mt-3">
                <Button
                    disabled={null === project || true === isSubmitting}
                    size="sm"
                    type="submit"
                >
                    {submitLabel}
                </Button>
                <Button
                    className="ms-2"
                    onClick={() => {
                        setProject({
                            name: NAME_NEW,
                            plot_ids: [],
                            substation_code: userSubstation?.display_name, // undefined if none,
                        });
                    }}
                    size="sm"
                    variant="outline-secondary"
                >
                    Reset
                </Button>
            </div>
        </Form>
    );
}
