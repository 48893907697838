import React, { useEffect, useMemo, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import { useDispatch, useSelector } from "react-redux";
import { buffer } from "@turf/buffer";
import { setList as setCountryList } from "../redux/countrySlice";
import { setCurrent as setCountry } from "../redux/countrySlice";
import {
    reset as resetLayers,
    setGeneral as setLayersGeneral,
    setSearch as setLayersSearch,
    setTypes as setLayersTypes,
} from "../redux/layersSlice";
import {
    setFilters as setUserFilters,
    setLayers as setUserLayers,
    setModes as setUserModes,
    setSelection as setUserSelection,
    setSubstation as setUserSubstation,
} from "../redux/userSlice";
import L from "leaflet";
import {
    LayerGroup,
    LayersControl,
    Marker,
    Pane,
    Polygon,
    ScaleControl,
    TileLayer,
    WMSTileLayer,
} from "react-leaflet";
import { useMap } from "react-leaflet/hooks";
import { Copy as IconCopy } from "react-bootstrap-icons";
import {
    COUNTRY_CENTROIDS,
    CountrySelector,
    CustomActions,
    Panel,
    Geoman,
    PolylineMeasure,
    Project,
    TileLayerWithHeader,
} from ".";
import { alertService, layerService, operatorService } from "../services";
import {
    availableModes,
    bindMetadataPopupActions,
    copyDeep,
    copyToClipboard,
    createMarkerPopup,
    createMetadataPopup,
    DEFAULT_LEGACY_FILTER,
    DEFAULT_LEGACY_FILTER_VALUES,
    filter,
    getCoordinatesForGeometry,
    getFlippedCoordinatesForGeometry,
    getGeoJsonFromElements,
    getLayerSettings,
    getMetadataPopup,
    getStyle,
    getUserProfile,
    inverseCoordinates,
    isObjectEmpty,
    isObjectEqual,
    markerIconInstallation,
    markerIconPin,
    markerIconSubstation,
    METADATA_POPUP_OPTIONS,
    MODE_DEFAULT,
    MODE_PROSPECTION,
    MODE_JBOX_LOCATION,
    resetFeatureStyle,
} from "../utils";

/**
 * The center piece of the application, rendering all the different components on top of the map.
 */
export function Map() {
    const dispatch = useDispatch();
    const map = useMap();
    const userProfile = getUserProfile();

    // avoid re-creation of WMTS params object on every re-render and thus a re-render of the layer
    const wmtsParamsGeoportail = useMemo(
        () => ({
            format: "image/png",
            layer: "CADASTRALPARCELS.PARCELLAIRE_EXPRESS",
            request: "GetTile",
            service: "wmts",
            style: "normal",
            tilematrixset: "PM_0_19",
            transparent: true,
            version: "1.0.0",
        }),
        [],
    );
    const wmtsParamsGeorisques = useMemo(
        () => ({
            format: "image/png",
            layers: ["GEORISQUES_SERVICES"], // ["CAVITE_LOCALISEE"]
            transparent: true,
        }),
        [],
    );
    const wmtsParamsLansstyrelsen = useMemo(
        () => ({
            format: "image/png",
            height: 512,
            layers: "text", // granser
            styles: "", // morkbakgrund
            transparent: true,
            width: 512,
        }),
        [],
    );
    const wmtsParamsLantmateriet = useMemo(
        () => ({
            format: "image/png",
            height: 512,
            layers: "granser",
            styles: "registerkarta",
            transparent: true,
            width: 512,
        }),
        [],
    );

    const country = useSelector((state) => state.country.value.current);
    const layersSearch = useSelector((state) => state.layers.value.search);
    const layersTypes = useSelector((state) => state.layers.value.types);
    const userFilters = useSelector((state) => state.user.value.filters);
    const userModes = useSelector((state) => state.user.value.modes);
    const userLayers = useSelector((state) => state.user.value.layers);
    const userSelection = useSelector((state) => state.user.value.selection);
    const userSubstation = useSelector((state) => state.user.value.substation);

    const markerRef = useRef(null);

    const [layerMunicipality, setLayerMunicipality] = useState(null);
    const [layerSelectedCoordinates, setLayerSelectedCoordinates] =
        useState(null);
    const [layerSelectedPlots, setLayerSelectedPlots] = useState([]);
    const [layerSubstationZones, setLayerSubstationZones] = useState(null);
    const [markerLayerGroup, setMarkerLayerGroup] = useState(null);
    const [panelViewMode, setPanelViewMode] = useState("layers");
    const [project, setProject] = useState(null);
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [searchInput, setSearchInput] = useState("");

    // ensure that the latest state is accessed when showing project component's selected plots
    // https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
    const projectRef = useRef(null);
    projectRef.current = project;

    let popup = null;

    // init country based on user, or default to France
    useEffect(() => {
        dispatch(setCountry(userProfile.country || "FR"));

        return () => {
            dispatch(setCountry(null));
        };
    }, []);

    // load available layers for country
    useEffect(() => {
        if (null !== country) {
            dispatch(resetLayers());
            loadLayers();
        }

        return () => {
            dispatch(resetLayers());
        };
    }, [country]);

    // listen to right-click to place a marker
    useEffect(() => {
        map.zoomControl.setPosition("bottomright");
        map.on("contextmenu", (e) => {
            map.closePopup(); // close any open marker popup
            const latitude = e.latlng.lat.toPrecision(8);
            const longitude = e.latlng.lng.toPrecision(8);
            setLayerSelectedCoordinates([latitude, longitude]);
        });

        // initialize marker layer group
        setMarkerLayerGroup(L.layerGroup().addTo(map));

        return () => {
            map.off("contextmenu");
        };
    }, [map]);

    // clear data when changing country
    useEffect(() => {
        reset();
    }, [country]);

    // react upon user selection
    useEffect(() => {
        if (null !== userSelection) {
            alertService.success(
                `${userSelection.type} <strong>${userSelection.display_name}</strong> selected`,
            );

            switch (userSelection.type) {
                case "Coordinates":
                    selectCoordinates();
                    break;
                case "Coordinates+":
                    reset();
                    selectCoordinatesSearch();
                    break;
                case "Installation":
                    selectInstallation();
                    break;
                case "Municipality":
                    reset();
                    selectMunicipality();
                    break;
                case "Municipality+":
                    selectMunicipalityCumulative();
                    break;
                case "Plot":
                    selectPlot();
                    break;
                case "Project":
                    reset();
                    selectProject();
                    break;
                case "Substation":
                    reset();
                    selectSubstation();
                    break;
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userSelection]);

    // get the municipality closest to center and update layers as municipality search
    const getMunicipalityNearCenterAndSearch = (center) => {
        operatorService
            .near(layersTypes.municipality._id, center)
            .then((municipalities) => {
                if (0 === municipalities.length) {
                    alertService.warn(
                        `No municipality found near <strong>${center[0]},${center[1]}</strong>`,
                    );
                    return;
                }

                searchLayersUpdate({
                    buffer: null,
                    municipality: municipalities.pop(),
                    substationZone: null,
                });

                map.setView(center, 15);
            })
            .finally(() => {
                setSearchIsLoading(false);
            });
    };

    const loadLayers = () => {
        // init map center based on active country
        const countryCentroid = COUNTRY_CENTROIDS[country];
        map.setView(
            [countryCentroid.lat, countryCentroid.lng],
            countryCentroid.zoom,
        );

        // retrieve available layers
        layerService.getAll().then((layers) => {
            if (layers === null) {
                alertService.error("Failed to connect to backend.");
                return;
            }

            const localLayersGeneral = [];
            const localLayersSearch = [];
            const localLayersTypes = {};
            const localUserFilters = {};
            const countryList = [];
            layers.forEach((layer) => {
                const layerSettings = getLayerSettings(layer);
                const localLayer = {
                    ...layerSettings,
                    _id: layer._id,
                    data: [], // null would stop the layer from showing in layer manager
                    name: layer.name,
                };

                if (country === localLayer.country) {
                    localLayersTypes[localLayer.type] = localLayer; // this potentially overrides layers, but we deliberately expect/want only one per type
                }

                if (false === countryList.includes(layerSettings.country)) {
                    countryList.push(layerSettings.country);
                }

                if (country === layerSettings.country) {
                    if ("general" === layerSettings.scope) {
                        localLayersGeneral.push(localLayer);
                        localUserFilters[localLayer._id] = {
                            formValues: DEFAULT_LEGACY_FILTER,
                            isLegacy: true,
                        };
                    } else {
                        localLayer.defaults = DEFAULT_LEGACY_FILTER_VALUES;
                        localLayersSearch.push(localLayer);
                    }
                }
            });

            if (false === isObjectEmpty(localUserFilters)) {
                dispatch(setUserFilters(localUserFilters));
            }

            dispatch(setCountryList(countryList.sort()));

            // retrieve country's general layers data and set them once and for all, since they never change
            const promiseResultMatch = []; // awkward way to match promise results back to a layer
            Promise.all(
                localLayersGeneral.map((localLayer) => {
                    promiseResultMatch.push(localLayer);
                    return operatorService.getElements(localLayer._id);
                }),
            ).then((responses) => {
                const localLayersGeneral = [];
                let promiseIndex = 0;
                responses.forEach((items) => {
                    if (0 < items.length) {
                        const localLayer = promiseResultMatch[promiseIndex++]; // get layer for result
                        const newLayer = {
                            ...localLayer,
                            data: [],
                            defaults: DEFAULT_LEGACY_FILTER_VALUES,
                        };
                        items.forEach((item) => {
                            newLayer.data.push({
                                _id: item._id,
                                display_name: item.display_name,
                                geometry: item.geometry,
                                properties: item.metadata,
                                type: "Feature",
                            });
                        });

                        localLayersGeneral.push(newLayer);
                    }
                });
                dispatch(setLayersGeneral(localLayersGeneral));
            });

            // set search layers without data
            dispatch(setLayersSearch(localLayersSearch));
            dispatch(setLayersTypes(localLayersTypes));

            // now that we have default styles through layersTypes, set Leaflet's default icon
            L.Marker.prototype.options.icon = markerIconPin();

            // init user's profile from localStorage or current values
            const localUserLayers = userProfile.layers;
            let localUserModes = userProfile.modes || userModes;
            if (undefined === localUserModes[country]) {
                localUserModes = { [country]: MODE_DEFAULT };
            }
            // init user's profile with layers based on current mode's active layers, only store active
            if (
                false === country in localUserLayers ||
                0 === localUserLayers[country].length
            ) {
                localUserLayers[country] = [];
                const availableModeLayers =
                    availableModes?.[localUserModes?.[country]]?.activeLayers?.[
                        country
                    ] || [];
                layers.forEach((layer) => {
                    if (true === availableModeLayers.includes(layer.name)) {
                        localUserLayers[country][layer._id] = layer.name;
                    }
                });
            }
            dispatch(setUserModes(localUserModes));
            dispatch(setUserLayers(localUserLayers));
        });
    };

    const onFeatureSelect = (layer, feature, event, layerRef = null) => {
        const container = L.DomUtil.create("div");
        resetFeatureStyle(layerRef);

        if ("plot" === layer.type) {
            // set active style
            const style = getStyle(layer, feature);
            event.target.setStyle({
                color: layer.style.active?.color || style.color,
                fillColor: layer.style.active?.color || style.fillColor,
                fillOpacity:
                    layer.style.active?.fillOpacity || style.fillOpacity,
                radius: layer.style.active?.radius || style.radius,
                weight: layer.style.active?.weight || style.weight,
            });

            // add project buttons
            const plotId = feature.display_name;

            if ("FR" === country) {
                let buttonProjectLabel;
                let buttonProjectOnclick;
                if (true === projectRef.current?.plot_ids?.includes(plotId)) {
                    buttonProjectLabel = "Remove from project";
                    buttonProjectOnclick = () => {
                        projectRemovePlot(plotId);
                        popup.close();
                    };
                } else {
                    buttonProjectLabel = "Add to project";
                    buttonProjectOnclick = () => {
                        projectAddPlots([plotId]);
                        popup.close();
                    };
                }

                const buttonProject = L.DomUtil.create(
                    "button",
                    "btn btn-primary btn-sm mb-3",
                    container,
                );
                buttonProject.type = "button";
                buttonProject.innerHTML = buttonProjectLabel;
                buttonProject.onclick = buttonProjectOnclick;
            }

            const buttonPlotId = L.DomUtil.create(
                "button",
                "btn btn-outline-secondary btn-sm mb-3 ms-2",
                container,
            );
            buttonPlotId.type = "button";
            buttonPlotId.innerHTML = renderToString(
                <>
                    {plotId}
                    <IconCopy className="ms-1" />
                </>,
            );
            buttonPlotId.onclick = () => {
                copyToClipboard(plotId, () => {
                    popup.close();
                });
            };
        } else if (
            true === ["municipality", "substation"].includes(layer.type)
        ) {
            // add search button
            const buttonSearch = L.DomUtil.create(
                "button",
                "btn btn-primary btn-sm mb-3",
                container,
            );
            buttonSearch.type = "button";
            buttonSearch.innerHTML = "Search";
            buttonSearch.onclick = () => {
                resetFeatureStyle(layerRef);

                if ("municipality" === layer.type) {
                    dispatch(
                        setUserSelection({
                            _id: feature.id,
                            display_name: feature.display_name,
                            geometry: feature.geometry,
                            type: "Municipality+",
                        }),
                    );
                } else if ("substation" === layer.type) {
                    const substation = {
                        _id: layer._id,
                        display_name: feature.display_name,
                        geometry: feature.geometry,
                        metadata: feature.properties,
                        type: "Substation",
                    };
                    dispatch(setUserSelection(substation));
                    dispatch(setUserSubstation(substation));
                }

                setSearchInput(feature.display_name);
                popup.close();
            };
        }

        const metadataDiv = L.DomUtil.create("div", null, container);
        metadataDiv.innerHTML = getMetadataPopup(
            country,
            userModes[country],
            layer.type,
            feature.display_name,
            feature.properties,
            layer.display_fields,
        );
        popup = L.popup(METADATA_POPUP_OPTIONS)
            .setContent(container)
            .setLatLng(event.latlng)
            .on("add", bindMetadataPopupActions)
            .on("remove", () => {
                // on popup close
                resetFeatureStyle(layerRef);
            })
            .openOn(map);
    };

    const onFeaturesSelect = (event) => {
        const bounds = event.boxSelectBounds;
        const featureIds = [];
        map.eachLayer((layer) => {
            if (
                layer instanceof L.Polygon &&
                "defaultOptions" in layer &&
                bounds.contains(layer.getBounds())
            ) {
                featureIds.push(layer.feature.display_name);
                layer.setStyle(layersTypes?.plot?.style?.active);
            }
        });

        projectAddPlots(featureIds);
    };

    const onSelectedCoordinates = () => {
        const marker = markerRef.current;
        if (marker != null) {
            marker
                .bindPopup(
                    createMarkerPopup(
                        marker,
                        (localUserSelection) =>
                            dispatch(setUserSelection(localUserSelection)),
                        (localSearchInput) => setSearchInput(localSearchInput),
                    ),
                )
                .openPopup();
        }
        copyToClipboard(layerSelectedCoordinates.join(", "));
    };

    // @TODO: reset feature style on map upon plot removal
    const projectRemovePlot = (id) => {
        const updatedSelectedPlotIds = [...projectRef.current.plot_ids];
        const indexPlotId = updatedSelectedPlotIds.indexOf(id);
        if (-1 !== indexPlotId) {
            updatedSelectedPlotIds.splice(indexPlotId, 1);
            setProject({
                ...projectRef.current,
                plot_ids: updatedSelectedPlotIds,
            });
        }
    };

    const projectAddPlots = (newIds) => {
        // update project with added plots
        if (null !== projectRef.current) {
            const duplicateIds = [];
            const localPlotIds = projectRef.current.plot_ids ?? [];
            newIds.forEach((newId) => {
                if (true === localPlotIds.includes(newId)) {
                    duplicateIds.push(newId);
                } else {
                    localPlotIds.push(newId);
                }
            });

            if (0 < duplicateIds.length) {
                alertService.warn(
                    `The plots <strong>${duplicateIds.join(
                        ", ",
                    )}</strong> have already been selected`,
                );
            }

            setProject({
                ...projectRef.current,
                plot_ids: localPlotIds,
            });
            // init project with added plots only
        } else {
            setProject({
                plot_ids: newIds,
                substation_code: userSubstation?.display_name,
            });
        }

        setPanelViewMode("project");
    };

    const selectCoordinates = () => {
        const center = [
            userSelection.data.latitude,
            userSelection.data.longitude,
        ];
        setLayerSelectedCoordinates(center);
        map.flyTo(center);
        setSearchIsLoading(false);
    };

    const selectCoordinatesSearch = () => {
        const center = [
            userSelection.data.latitude,
            userSelection.data.longitude,
        ];
        setLayerSelectedCoordinates(center);
        getMunicipalityNearCenterAndSearch(center);
    };

    const selectInstallation = () => {
        const center = [
            userSelection.data.geometry.coordinates[1],
            userSelection.data.geometry.coordinates[0],
        ];
        getMunicipalityNearCenterAndSearch(center);

        // @WARNING: if the layer JBox and IECharge is active, the following marker will be duplicated by the element of that layer
        const layerInstallation = layersSearch
            .filter((layerSearch) => "installation" === layerSearch.type)
            .pop();
        L.marker(center, {
            icon: markerIconInstallation(
                layerInstallation.style.default.fillColor,
            ),
        })
            .addTo(markerLayerGroup)
            .bindPopup(
                createMetadataPopup(
                    country,
                    userModes[country],
                    "installation",
                    userSelection.display_name,
                    userSelection.metadata,
                    layerInstallation.display_fields,
                ),
            );
    };

    const selectMunicipality = () => {
        // @WARNING: sometimes the userSelection's geometry doesn't match the selected municipality...
        const coordinates = inverseCoordinates(
            getCoordinatesForGeometry(userSelection.geometry),
        );
        map.fitBounds(coordinates);
        setLayerMunicipality(coordinates);

        searchLayersUpdate({
            buffer: null,
            municipality: userSelection,
            substationZone: null,
        });
        setSearchIsLoading(false);
    };

    // user runs search on a municipality level through the map
    const selectMunicipalityCumulative = () => {
        const coordinates = inverseCoordinates(
            getCoordinatesForGeometry(userSelection.geometry),
        );
        setLayerMunicipality(coordinates);

        // if we have already a substation, retrieve the according substation zone too
        if (null !== userSubstation) {
            operatorService
                .getElement(layersTypes.substationZone._id, {
                    phrase: [
                        {
                            key: "metadata.substations.code",
                            value: userSubstation.display_name,
                        },
                    ],
                })
                .then((substationZone) => {
                    if (null === substationZone) {
                        alertService.warn(
                            `No substation zone found for code <strong>${userSubstation.display_name}</strong>`,
                        );
                        setSearchIsLoading(false);
                        return;
                    }

                    searchLayersUpdate({
                        buffer: null,
                        isDataCumulative: false,
                        municipality: userSelection,
                        substationZone: substationZone,
                    });
                });
        } else {
            searchLayersUpdate({
                buffer: null,
                isDataCumulative: true,
                municipality: userSelection,
                substationZone: null,
            });
        }
    };

    // user selects a plot from search suggestions
    const selectPlot = async () => {
        const localLayerSelectedPlots = ["FI", "SE"].includes(country)
            ? []
            : layerSelectedPlots; // NWJSP-786
        const coordinates = [];
        let plots = [];

        // do an additional plot search by display name to show eventual duplicates
        if (true === ["FI", "SE"].includes(country)) {
            plots = await operatorService.getElements(
                layersTypes.plot._id,
                {},
                {
                    equals: [
                        {
                            key: "display_name",
                            value: userSelection.display_name,
                        },
                    ],
                },
            );
        } else {
            plots.push(copyDeep(userSelection));
        }
        plots.forEach((plot) => {
            const plotCoordinates = getFlippedCoordinatesForGeometry(
                plot.geometry,
            );
            coordinates.push(L.geoJSON(plot.geometry).getBounds()); // only push bounds for map zoom
            localLayerSelectedPlots.push({
                _id: plot._id,
                coordinates: plotCoordinates,
                display_name: plot.display_name,
                properties: plot.metadata,
            });
        });
        setLayerSelectedPlots(localLayerSelectedPlots);

        operatorService
            .intersect(layersTypes.municipality._id, userSelection._id)
            .then((municipalities) => {
                if (0 === municipalities.length) {
                    alertService.warn(
                        `No municipality interects with plot <strong>${userSelection.display_name}</strong>`,
                    );
                    return;
                }

                const bufferOneKilometer = buffer(userSelection.geometry, 1, {
                    units: "kilometers",
                });
                searchLayersUpdate(
                    {
                        buffer: bufferOneKilometer,
                        municipality: municipalities.pop(),
                        substationZone: null,
                    },
                    true,
                );

                map.fitBounds(coordinates);
            })
            .finally(() => {
                setSearchIsLoading(false);
            });
    };

    // user selects a project from search suggestions
    const selectProject = () => {
        if (false === "name.substation" in userSelection.metadata) {
            alertService.warn(
                `No substation name found in metadata of project <strong>${userSelection.display_name}</strong>`,
            );
            setSearchIsLoading(false);
            return;
        }

        if (false === "Plot.Id_JSP" in userSelection.metadata) {
            alertService.warn(
                `No plots found in metadata of project <strong>${userSelection.display_name}</strong>`,
            );
            setSearchIsLoading(false);
            return;
        }

        const substationCode = userSelection.metadata["Substation.code_JSP"];
        return operatorService
            .getElement(layersTypes.substationZone._id, {
                phrase: [
                    {
                        key: "metadata.substations.code",
                        value: substationCode,
                    },
                ],
            })
            .then((substationZone) => {
                if (null === substationZone) {
                    alertService.warn(
                        `No substation zone found for code <strong>${substationCode}</strong>`,
                    );
                    setSearchIsLoading(false);
                    return;
                }

                searchLayersUpdate({
                    buffer: null,
                    municipality: null,
                    substationZone: substationZone,
                });
                map.fitBounds(
                    inverseCoordinates(
                        getCoordinatesForGeometry(substationZone.geometry),
                    ),
                );

                // project has no plots, stop here
                let projectPlotIds = userSelection.metadata["Plot.Id_JSP"];
                if (null === projectPlotIds) {
                    alertService.warn(
                        `No plots found for project <strong>${userSelection.display_name}</strong>`,
                    );
                    setSearchIsLoading(false);
                    return;
                }

                // prepare parallelization of plot detail queries
                const promises = [];
                projectPlotIds = projectPlotIds
                    .split(",")
                    .map((plotId) => plotId.trim());
                projectPlotIds.forEach((plotId) => {
                    promises.push(
                        operatorService.getElement(layersTypes.plot._id, {
                            phrase: [
                                {
                                    key: "display_name",
                                    value: plotId,
                                },
                            ],
                        }),
                    );
                });

                Promise.all(promises).then((plots) => {
                    const localLayerSelectedPlots = [];
                    let coordinates = [];
                    let countSuccessfullyRetrievedPlots = 0;
                    plots.forEach((plot) => {
                        if (null !== plot) {
                            countSuccessfullyRetrievedPlots++;

                            const inversedCoordinates = inverseCoordinates(
                                plot.geometry.coordinates[0],
                            );
                            localLayerSelectedPlots.push({
                                id: plot._id,
                                coordinates: inversedCoordinates,
                                display_name: plot.display_name,
                                properties: plot.metadata,
                            });
                            coordinates = [
                                ...coordinates,
                                ...inversedCoordinates,
                            ];
                        }
                    });

                    if (
                        projectPlotIds.length !==
                        countSuccessfullyRetrievedPlots
                    ) {
                        alertService.warn(
                            `<strong>${countSuccessfullyRetrievedPlots}</strong> of the project's <strong>${projectPlotIds.length}</strong> plots have been matched.`,
                        );
                    }

                    setLayerSelectedPlots(localLayerSelectedPlots);
                    setProject({
                        name: userSelection.display_name,
                        plot_ids: localLayerSelectedPlots.map(
                            (plot) => plot.display_name,
                        ),
                        substation_code: substationCode,
                    });
                    setSearchIsLoading(false);
                });
            });
    };

    const selectSubstation = () => {
        const center = inverseCoordinates([
            userSelection.geometry.coordinates,
        ]).pop();
        const substationCode = userSelection.display_name;
        const title = `Substation ${substationCode}`;
        L.marker(center, {
            icon: markerIconSubstation(
                layersTypes.substation.style.default.fillColor,
            ),
        })
            .addTo(markerLayerGroup)
            .bindPopup(
                createMetadataPopup(
                    country,
                    userModes[country],
                    "substation",
                    title,
                    userSelection.metadata,
                    layersTypes.substation.display_fields,
                ),
            );

        undefined !== layersTypes.substationZone &&
            operatorService
                .getElement(layersTypes.substationZone._id, {
                    phrase: [
                        {
                            key: "metadata.substations.code",
                            value: substationCode,
                        },
                    ],
                })
                .then((substationZone) => {
                    if (null !== substationZone) {
                        const zones = [
                            inverseCoordinates(
                                getCoordinatesForGeometry(
                                    substationZone.geometry,
                                ),
                            ),
                        ];
                        if (
                            availableModes[userModes[country]]
                                .layerSubstationZonesActive
                        ) {
                            setLayerSubstationZones(zones);
                        }
                        map.fitBounds(zones);

                        searchLayersUpdate({
                            buffer: null,
                            municipality: null,
                            substationZone: substationZone,
                        });
                    } else {
                        alertService.warn(
                            `No substation zone found for ${substationCode}`,
                        );
                    }
                })
                .finally(() => {
                    setSearchIsLoading(false);
                });
    };

    // update data of one given search data layer
    const searchLayersUpdate = (intersectObject) => {
        const searchLayerCount = layersSearch.length;

        // reset data of inactive layers by storing it in oldLayers, to be pushed to layersSearch later
        const oldLayers = [];
        layersSearch
            .filter(
                (layer) =>
                    !(
                        country in userLayers &&
                        true ===
                            Object.keys(userLayers[country]).includes(layer._id)
                    ),
            )
            .forEach((layer) => {
                oldLayers.push({
                    ...layer,
                    data: [], // null would stop the layer from showing in layer manager
                });
            });

        // intersect current selection with user's active layers, then update layersSearch including oldLayers
        const updatedLayers = [];
        const localUserFilters = copyDeep(userFilters);
        layersSearch
            .filter(
                (layer) =>
                    true === country in userLayers &&
                    true ===
                        Object.keys(userLayers[country]).includes(layer._id),
            )
            .forEach((layer) => {
                const onElements = (elements, fields = null) => {
                    alertService.info(
                        `${elements.length} intersecting elements from layer <strong>${layer.name}</strong> loaded`,
                    );

                    let data = getGeoJsonFromElements(elements);

                    // apply client-side filtering for legacy layers
                    if (
                        true === localUserFilters[layer._id].isLegacy &&
                        false ===
                            isObjectEqual(
                                localUserFilters[layer._id].formValues,
                                DEFAULT_LEGACY_FILTER,
                            )
                    ) {
                        data = filter(
                            localUserFilters[layer._id].formValues,
                            data,
                        );
                    }

                    if (true === intersectObject.isDataCumulative) {
                        data = [...layer.data, ...data]; // merge data, never for plot layers though
                    }

                    updatedLayers.push({
                        ...layer,
                        areFeaturesHidden: areFeaturesHidden,
                        data: data,
                        defaultFilters: fields,
                    });

                    if (
                        searchLayerCount ===
                        oldLayers.length + updatedLayers.length
                    ) {
                        setSearchIsLoading(false);
                    }

                    const updatedLayersSearch = [
                        ...oldLayers,
                        ...updatedLayers,
                    ];
                    dispatch(setLayersSearch(updatedLayersSearch));
                };

                // only show certain layers, based on modes.js
                let areFeaturesHidden =
                    false ===
                    availableModes[userModes[country]]?.unhiddenLayers?.[
                        country
                    ].includes(layer.name);

                let userFilters = {};

                // New layers (search endpoint): prepare backend filtering
                if (0 < layer.search_fields.length) {
                    // municipality or substation zone is needed for intersect to avoid a massive query
                    if (
                        null === intersectObject.municipality &&
                        null === intersectObject.substationZone
                    ) {
                        alertService.warn(
                            `No municipality or substation zone to intersect with layer <strong>${layer.name}</strong>`,
                        );
                        return;
                    }

                    userFilters = {
                        equals: [],
                        geometries: [],
                        in: [],
                        phrase: [],
                        range: [],
                        isLegacy: false,
                    };

                    if (
                        "highVoltageLine" !== layer.type &&
                        null !== intersectObject.buffer
                    ) {
                        userFilters.geometries.push(
                            intersectObject.buffer.geometry,
                        );
                    }

                    // for non-FR countries, use municipality's geometry if exists, otherwise, use substation zone's
                    if ("FR" !== country) {
                        if (0 === userFilters.geometries.length) {
                            if (null !== intersectObject.municipality) {
                                userFilters.geometries.push(
                                    intersectObject.municipality.geometry,
                                );
                            } else {
                                userFilters.geometries.push(
                                    intersectObject.substationZone.geometry,
                                );
                            }
                        }
                        // for FR
                    } else {
                        // always add municipality's geometry, if exists
                        if (
                            0 === userFilters.geometries.length &&
                            null !== intersectObject.municipality
                        ) {
                            userFilters.geometries.push(
                                intersectObject.municipality.geometry,
                            );
                        }
                        if ("plot" === layer.type) {
                            if (
                                "FR" === country &&
                                null !== intersectObject.substationZone
                            ) {
                                userFilters.in.push({
                                    choices: [], // part of the NWJSP-650 hack
                                    embeddedDocument: true,
                                    key: "metadata.substations.code",
                                    value: [
                                        intersectObject.substationZone.metadata
                                            .substations.code,
                                    ],
                                });
                            }
                            if (null === intersectObject.municipality) {
                                // implies null !== intersectObject.substationZone
                                userFilters.geometries.push(
                                    intersectObject.substationZone.geometry,
                                );
                                userFilters.range.push({
                                    embeddedDocument: true,
                                    key: "metadata.substations.NUM_rating",
                                    value: [100, null],
                                });
                            }
                        } else {
                            // only HTA Lines for now
                            if (null !== intersectObject.substationZone) {
                                userFilters.geometries.push(
                                    intersectObject.substationZone.geometry,
                                );
                                userFilters.in.push({
                                    choices: [], // part of the NWJSP-650 hack
                                    embeddedDocument: true,
                                    key: "metadata.substations.code",
                                    value: [
                                        intersectObject.substationZone.metadata
                                            .substations.code,
                                    ],
                                });
                            }
                        }
                    }

                    operatorService
                        .getElements(
                            layer._id,
                            {},
                            {},
                            userFilters.geometries,
                            {
                                path: "metadata.substations",
                                fieldsMust: userFilters,
                                fieldsShould: [],
                            },
                        )
                        .then((elements) => onElements(elements, userFilters));
                    // Legacy layers (intersect endpoint): prepare client-side filters
                } else {
                    const formValues = copyDeep(DEFAULT_LEGACY_FILTER_VALUES);

                    if (
                        "FI" === country &&
                        null !== userSubstation &&
                        ("substationZone" === layer.type ||
                            "Substations Buffer" === layer.name)
                    ) {
                        formValues.condition = "eq";
                        formValues.key = "substations";
                        formValues.keyNextLevel = "code";
                        formValues.value = userSubstation.display_name;
                    }

                    userFilters = {
                        formValues: {
                            0: formValues,
                        },
                        isLegacy: true,
                    };

                    if (null !== intersectObject.buffer) {
                        operatorService
                            .getElements(layer._id, {}, {}, [
                                intersectObject.buffer.geometry,
                            ])
                            .then((elements) => onElements(elements));
                    } else {
                        const elementId =
                            null !== intersectObject.substationZone
                                ? intersectObject.substationZone._id
                                : intersectObject.municipality._id;
                        operatorService
                            .intersect(layer._id, elementId)
                            .then((elements) => onElements(elements));
                    }
                }

                // init user filters, this overrides potentially existing filters for that layer
                localUserFilters[layer._id] = userFilters;
            });

        dispatch(setUserFilters(localUserFilters));
    };

    // clear map
    const reset = () => {
        // clear non-search data layers
        setLayerMunicipality(null);
        setLayerSelectedCoordinates(null);
        setLayerSelectedPlots([]);
        setLayerSubstationZones(null);

        // clear search layers
        if (layersSearch !== null) {
            // strip layer objects back to initial state, i.e. empty data object etc.
            const updatedLayersSearch = layersSearch.map((layer) => {
                return {
                    ...layer,
                    data: [], // null would stop the layer from showing in layer manager
                };
            });

            dispatch(setLayersSearch(updatedLayersSearch));
        }

        // clear markers
        if (markerLayerGroup !== null) {
            markerLayerGroup.clearLayers();
        }

        // close popups
        map.closePopup();
    };

    return (
        <>
            <div id="block-interaction" />
            <LayersControl position="topright">
                <Pane name="map-osm-greyscale">
                    <LayersControl.BaseLayer
                        checked={false}
                        name="OpenStreetMap Greyscale"
                    >
                        <TileLayer
                            attribution="© OpenStreetMap contributors, Tiles style by Humanitarian OpenStreetMap Team hosted by OpenStreetMap France"
                            maxZoom={22}
                            url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>
                </Pane>
                <Pane name="map-osm">
                    <LayersControl.BaseLayer
                        checked={false}
                        name="OpenStreetMap"
                    >
                        <TileLayer
                            attribution="© OpenStreetMap contributors"
                            maxZoom={22}
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>
                </Pane>
                <Pane name="map-otm">
                    <LayersControl.BaseLayer checked={false} name="OpenTopoMap">
                        <TileLayer
                            attribution="Map data: © OpenStreetMap contributors, SRTM | Map style: © OpenTopoMap (CC-BY-SA)"
                            maxZoom={22}
                            url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>
                </Pane>
                <Pane name="map-google-satellite">
                    <LayersControl.BaseLayer
                        checked={false}
                        name="GoogleMaps Satellite"
                    >
                        <TileLayer
                            attribution="© Google Maps"
                            maxZoom={22}
                            subdomains={["mt1", "mt2", "mt3"]}
                            url="https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
                        />
                        {/* lyrs options, can be accumulated like s,h:
                            h = roads only
                            m = standard roadmap
                            p = terrain
                            r = somehow altered roadmap
                            s = satellite only
                            t = terrain only
                            y = hybrid */}
                    </LayersControl.BaseLayer>
                </Pane>
                <Pane name="map-google-satellite-labels">
                    <LayersControl.BaseLayer
                        checked={true}
                        name="GoogleMaps Satellite Only"
                    >
                        <TileLayer
                            attribution="© Google Maps"
                            maxZoom={22}
                            subdomains={["mt1", "mt2", "mt3"]}
                            url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                        />
                    </LayersControl.BaseLayer>
                </Pane>
                {"FI" === country && (
                    <>
                        <Pane name="map-maastokartta">
                            <LayersControl.Overlay
                                checked={false}
                                name="Rinnevarjoste"
                            >
                                <TileLayer
                                    attribution={
                                        "Maanmittauslaitos, Esri Finland"
                                    }
                                    maxNativeZoom={15}
                                    maxZoom={22}
                                    minZoom={0}
                                    opacity={1}
                                    tileSize={256}
                                    url={
                                        "https://tiles.arcgis.com/tiles/6G8plNyk35GEC5T4/arcgis/rest/services/Rinnevarjostus_webm/MapServer/tile/{z}/{y}/{x}"
                                    }
                                />
                            </LayersControl.Overlay>
                            <LayersControl.Overlay
                                checked={false}
                                name="Maastokartta"
                            >
                                <TileLayer
                                    attribution={
                                        "Maanmittauslaitos, Esri Finland"
                                    }
                                    maxNativeZoom={21}
                                    maxZoom={22}
                                    minZoom={0}
                                    opacity={0.75}
                                    tileSize={256}
                                    url={
                                        "https://avoin-karttakuva.maanmittauslaitos.fi/avoin/wmts/1.0.0/maastokartta/default/WGS84_Pseudo-Mercator/{z}/{y}/{x}.png?api-key=4fd4318f-7377-48c5-a292-b736fa4d5593"
                                    }
                                />
                            </LayersControl.Overlay>
                        </Pane>
                    </>
                )}
                {"FR" === country && (
                    <>
                        <Pane name="map-geoportail">
                            <LayersControl.Overlay
                                checked={true}
                                name="Géoportail"
                            >
                                <WMSTileLayer
                                    attribution={"Géoportail"}
                                    maxNativeZoom={19}
                                    maxZoom={22}
                                    minZoom={0}
                                    opacity={0.5}
                                    params={wmtsParamsGeoportail}
                                    tileSize={256}
                                    url={
                                        "https://data.geopf.fr/wmts?tilematrix={z}&tilerow={y}&tilecol={x}"
                                    }
                                />
                            </LayersControl.Overlay>
                        </Pane>
                        <Pane name="map-georisques">
                            <LayersControl.Overlay
                                checked={false}
                                name="Géorisques"
                            >
                                <WMSTileLayer
                                    attribution={"Géorisques"}
                                    maxNativeZoom={21}
                                    maxZoom={22}
                                    minZoom={12}
                                    opacity={0.5}
                                    params={wmtsParamsGeorisques}
                                    tileSize={512}
                                    url={
                                        "https://www.georisques.gouv.fr/services?"
                                    }
                                />
                            </LayersControl.Overlay>
                        </Pane>
                    </>
                )}
                {"SE" === country && (
                    <>
                        {/*<Pane name="map-lantmateriet">*/}
                        {/*    <LayersControl.BaseLayer checked={true} name="Lantmäteriet">*/}
                        {/*        <TileLayerWithHeader*/}
                        {/*            attribution="© <a href='https://www.lantmateriet.se/en/'>Lantmäteriet</a> Topografisk Webbkarta Visning, CCB"*/}
                        {/*            headers={{*/}
                        {/*                'Authorization': 'Bearer %THE_JWT_GOES_HERE%',*/}
                        {/*            }}*/}
                        {/*            maxZoom={22}*/}
                        {/*            minZoom={0}*/}
                        {/*            opacity={1}*/}
                        {/*            url={`https://api.lantmateriet.se/open/topowebb-ccby/v1/wmts/1.0.0/topowebb/default/3006/{z}/{y}/{x}.png`}*/}
                        {/*            />*/}
                        {/*    </LayersControl.BaseLayer>*/}
                        {/*</Pane>}*/}
                        <Pane name="map-länsstyrelsen">
                            <LayersControl.Overlay
                                checked={true}
                                name="Länsstyrelsen"
                            >
                                <WMSTileLayer
                                    attribution={"Länsstyrelsen"}
                                    maxZoom={22}
                                    minZoom={0}
                                    opacity={1}
                                    params={wmtsParamsLansstyrelsen}
                                    url={
                                        "https://ext-geoportal.lansstyrelsen.se/wmsproxy/wms/fastighet?"
                                    }
                                />
                            </LayersControl.Overlay>
                        </Pane>
                        <Pane name="map-lantmäteriet-plots">
                            <LayersControl.Overlay
                                checked={true}
                                name="Lantmäteriet Plots"
                            >
                                <WMSTileLayer
                                    attribution={"Lantmäteriet"}
                                    maxZoom={22}
                                    minZoom={0}
                                    opacity={1}
                                    params={wmtsParamsLantmateriet}
                                    url={
                                        "https://minkarta.lantmateriet.se/map/fastighetsindelning?"
                                    }
                                />
                            </LayersControl.Overlay>
                        </Pane>
                    </>
                )}
                {layerMunicipality && (
                    <Pane name="municipality">
                        <LayersControl.Overlay
                            checked={true}
                            name="Selected municipality"
                        >
                            <LayerGroup>
                                <Polygon
                                    pathOptions={
                                        layersTypes?.municipality?.style?.active
                                    }
                                    positions={layerMunicipality}
                                />
                            </LayerGroup>
                        </LayersControl.Overlay>
                    </Pane>
                )}
                {0 < layerSelectedPlots.length && (
                    <Pane name="selected-plots">
                        <LayersControl.Overlay checked name="Selected plots">
                            <LayerGroup>
                                {layerSelectedPlots.map(
                                    (layerSelectedPlot, key) => (
                                        <Polygon
                                            eventHandlers={{
                                                click: (event) => {
                                                    popup = createMetadataPopup(
                                                        country,
                                                        userModes[country],
                                                        "plot",
                                                        layerSelectedPlot.display_name,
                                                        layerSelectedPlot.properties,
                                                        layersTypes.plot
                                                            .display_fields,
                                                    );
                                                    popup
                                                        .setLatLng(event.latlng)
                                                        .openOn(map);
                                                },
                                            }}
                                            key={key}
                                            pathOptions={
                                                layersTypes?.plot?.style?.active
                                            }
                                            positions={
                                                layerSelectedPlot.coordinates
                                            }
                                        />
                                    ),
                                )}
                            </LayerGroup>
                        </LayersControl.Overlay>
                    </Pane>
                )}
                {layerSubstationZones && (
                    <Pane name="substation-zones">
                        <LayersControl.Overlay
                            checked={true}
                            name="Substation zones"
                        >
                            <LayerGroup>
                                {layerSubstationZones.map(
                                    (layerSubstationZone, key) => (
                                        <Polygon
                                            key={key}
                                            pathOptions={
                                                layersTypes?.substationZone
                                                    ?.style?.default
                                            }
                                            positions={layerSubstationZone}
                                        />
                                    ),
                                )}
                            </LayerGroup>
                        </LayersControl.Overlay>
                    </Pane>
                )}
                {layerSelectedCoordinates && (
                    <Pane name="selected-coordinates">
                        <LayersControl.Overlay
                            checked
                            name="Selected coordinates"
                        >
                            <LayerGroup>
                                <Marker
                                    icon={markerIconPin()}
                                    draggable={true}
                                    eventHandlers={{
                                        add: onSelectedCoordinates,
                                        click: onSelectedCoordinates,
                                    }}
                                    position={layerSelectedCoordinates}
                                    ref={markerRef}
                                />
                            </LayerGroup>
                        </LayersControl.Overlay>
                    </Pane>
                )}
            </LayersControl>
            <Panel
                onFeatureSelect={onFeatureSelect}
                onFeaturesSelect={onFeaturesSelect}
                project={project}
                projectRemovePlot={projectRemovePlot}
                searchInput={searchInput}
                searchIsLoading={searchIsLoading}
                setProject={setProject}
                setSearchInput={setSearchInput}
                setSearchIsLoading={setSearchIsLoading}
                setViewMode={setPanelViewMode}
                viewMode={panelViewMode}
            />
            <CustomActions />
            <Geoman />
            <PolylineMeasure />
            <ScaleControl imperial={true} position="bottomleft" />
            <CountrySelector setSearchInput={setSearchInput} />
        </>
    );
}
