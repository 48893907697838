import { loginRequest } from "../authConfig";

let msalInstance;

export const initAuth = (instance) => {
    msalInstance = instance;
};

export const getToken = async () => {
    const request = {
        ...loginRequest,
        account: msalInstance.getActiveAccount() || {},
    };

    const authResult = await msalInstance.acquireTokenSilent(request);
    return authResult.accessToken;
};

export const fetchWithMsal = async (input, options = null) => {
    const headers = options?.headers || new Headers();
    const token = await getToken();
    const bearer = `Bearer ${token}`;
    headers.append("Accept", "application/json");
    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    let optionsWithHeader = {
        ...options,
        headers: headers,
    };

    return fetch(input, optionsWithHeader);
};
