import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import L from "leaflet";
import { useMap } from "react-leaflet/hooks";
import { BoxSelect, FilterLine } from ".";
import { availableModes, renderLayer, resetMap } from "../utils";

/**
 * Responsible for showing the layers on the map and their filter lines in the panel.
 *
 * @param isFiltersShow             whether to show the filters or not, the component itself is required to display data on map
 * @param isGeneral                 can be either a search or a general layer
 * @param isSearchLoading           hide filter lines when search is loading
 * @param onFeatureSelect
 * @param onFeaturesSelect
 */
export function DataLayers({
    isFiltersShow,
    isGeneral,
    isSearchLoading,
    onFeatureSelect,
    onFeaturesSelect,
}) {
    const layerGroup = L.layerGroup();
    const map = useMap();

    const country = useSelector((state) => state.country.value.current);
    const layers = useSelector((state) =>
        true === isGeneral
            ? state.layers.value.general
            : state.layers.value.search,
    );
    const userFilters = useSelector((state) => state.user.value.filters);
    const userModes = useSelector((state) => state.user.value.modes);
    const userLayers = useSelector((state) => state.user.value.layers);
    const userSelection = useSelector((state) => state.user.value.selection);

    if (false === isGeneral) {
        map.boxSelect.enable();
        // @TODO: understand and fix "boxselectend" firing dozens of times instead once
        map.on("boxselectend", onFeaturesSelect);
    }

    const [dataLayers, setDataLayers] = useState([]); // store layer object, Leaflet's map reference, and the order number
    const [lastHighlightedKey, setLastHighlightedKey] = useState(null);
    const [orderIndex, setOrderIndex] = useState(1); // store the highest order index of all layers

    useEffect(() => {
        resetMap(map, dataLayers, layerGroup);
        layerGroup.addTo(map);

        // only render search layers, since general layers don't change
        if (false === isGeneral) {
            renderSearch();
            // render general layers
        } else if (null !== layers) {
            const localDataLayers = [];
            let localOrderIndex = orderIndex;
            layers
                .filter(
                    (localLayer) =>
                        true === country in userLayers &&
                        true ===
                            Object.keys(userLayers[country]).includes(
                                localLayer._id,
                            ),
                )
                .forEach((localDataLayer) => {
                    localDataLayers.push({
                        layer: localDataLayer,
                        layerRef: renderLayer(
                            localDataLayer,
                            layerGroup,
                            onFeatureSelect,
                        ),
                        order: localOrderIndex++,
                    });
                });

            setDataLayers(localDataLayers);
            setOrderIndex(localOrderIndex);
        }

        return () => {
            resetMap(map, dataLayers, layerGroup);
            setDataLayers([]);
            setOrderIndex(1);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layers]);

    // render all search layers
    const renderSearch = () => {
        const localDataLayers = [];
        if (null !== layers) {
            let localOrderIndex = orderIndex + 1;
            const availableModeLayers =
                availableModes?.[userModes?.[country]]?.activeLayers?.[
                    country
                ] || [];
            layers
                // except for new layers following a user search
                .filter(
                    (layer) =>
                        undefined !== userLayers?.[country]?.[layer._id] && // only user's active layers
                        (0 < layer.data.length || // only layers with data
                            (0 < layer.search_fields.length &&
                                null !== userSelection)),
                )
                .toSorted((a, b) => {
                    // first element will be added first and hence lowest on map
                    const indexA = availableModeLayers.indexOf(a.name);
                    const indexB = availableModeLayers.indexOf(b.name);
                    if (indexA === indexB) {
                        return 0; // doesn't matter
                    } else if (-1 === indexA) {
                        return -1; // b is listed but not a, make b first
                    } else if (-1 === indexB) {
                        return 1; // a is listed but not b, make a first
                    } else if (indexA > indexB) {
                        return -1; // b is above a (lower index), make b first
                    } else {
                        // indexA < indexB
                        return 1; // a is above b (lower index), make a first
                    }
                })
                .forEach((layer) => {
                    // add or update data
                    const layerRef = renderLayer(
                        layer,
                        layerGroup,
                        onFeatureSelect,
                    );
                    localDataLayers.push({
                        layer: layer,
                        layerRef: layerRef,
                        order: localOrderIndex++, // needs to be inverted since last added layer is on top
                    });
                    if (true === layer.areFeaturesHidden) {
                        layerRef.remove();
                    }
                });
            setOrderIndex(localOrderIndex);
        }

        setDataLayers(localDataLayers);
    };

    // data layer has been filtered from embedded filter line component, render result and put on top
    const update = (updatedDataLayer, bringToFront = false) => {
        updatedDataLayer.layerRef.remove(); // remove layer from map, this is for general layers
        layerGroup.addTo(map);

        // update given layer and set top order, if requested
        const updatedDataLayers = dataLayers
            .map((localDataLayer) => {
                if (localDataLayer.layer._id === updatedDataLayer.layer._id) {
                    const localUserFilter =
                        userFilters?.[localDataLayer.layer._id];

                    // get filtered substations to inform dynamic styling
                    let filteredSubstationCodes = [];
                    if (
                        "plot" === localDataLayer.layer.type &&
                        undefined !== localUserFilter?.in
                    ) {
                        filteredSubstationCodes = localUserFilter.in
                            .filter(
                                (filter) =>
                                    "metadata.substations.code" === filter.key,
                            )
                            .map((filter) => filter.value)
                            .pop(); // filter is an array of substation codes
                    }

                    let localDataLayerOrder;
                    if (true === bringToFront) {
                        // set to highest order index
                        localDataLayerOrder = orderIndex + 1;
                        setOrderIndex(localDataLayerOrder);
                    } else {
                        // keep previous order
                        localDataLayerOrder = localDataLayer.order;
                    }

                    return {
                        layer: updatedDataLayer.layer,
                        layerRef: renderLayer(
                            updatedDataLayer.layer,
                            layerGroup,
                            onFeatureSelect,
                            filteredSubstationCodes,
                        ), // put layer back into layerGroup
                        order: localDataLayerOrder,
                    };
                }

                // don't touch the others
                return localDataLayer;
            })
            // have lowest order first
            .sort((a, b) => (a.order < b.order ? -1 : 1))
            // bring them to top in the right order, lowest first
            .map((localDataLayer) => {
                localDataLayer.layerRef.bringToFront();
                if (true === localDataLayer.layer.areFeaturesHidden) {
                    map.removeLayer(localDataLayer.layerRef);
                }
                return localDataLayer;
            });

        setDataLayers(updatedDataLayers);

        return updatedDataLayers
            .filter(
                (localLayer) =>
                    localLayer.layer._id === updatedDataLayer.layer._id,
            )
            .pop();
    };

    if (
        true === isFiltersShow &&
        false === isSearchLoading &&
        0 < dataLayers.length
    ) {
        return (
            <div
                className={`filter ${true === isGeneral ? "stripes" : "striped"}`}
            >
                <ul className="list-group list-group-flush">
                    {dataLayers
                        .sort((a, b) => (a.order > b.order ? -1 : 1)) // inverse since Leaflet is first added, lowest zIndex
                        .map((dataLayer) => (
                            <FilterLine
                                key={dataLayer.layer._id}
                                dataLayer={dataLayer}
                                lastHighlightedKey={lastHighlightedKey}
                                layers={layers}
                                setDataLayers={setDataLayers}
                                setLastHighlightedKey={setLastHighlightedKey}
                                updateDataLayer={update}
                            />
                        ))}
                </ul>
            </div>
        );
    }
}
