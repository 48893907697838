import { createSlice } from "@reduxjs/toolkit";

export const layersSlice = createSlice({
    name: "layers",
    initialState: {
        value: {
            general: null, // null indicates initial state, an empty array means no results
            search: null, // null indicates initial state, an empty array means no results
            types: {}, // store layers by type to have access to IDs, styles, display_types, ...
        },
    },
    reducers: {
        reset: (state) => {
            state.value.general = null;
            state.value.search = null;
            state.value.types = {};
        },
        setGeneral: (state, action) => {
            state.value.general = action.payload;
        },
        setSearch: (state, action) => {
            state.value.search = action.payload;
        },
        setTypes: (state, action) => {
            state.value.types = action.payload;
        },
    },
});

export const { reset, setGeneral, setSearch, setTypes } = layersSlice.actions;

export default layersSlice.reducer;
