import { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import { useMap } from "react-leaflet/hooks";
import L from "leaflet";
import "leaflet-easybutton/src/easy-button";
import "leaflet.geodesic";
import "leaflet.heightgraph";
import "leaflet.heightgraph/dist/L.Control.Heightgraph.min.css";
import {
    Bullseye as IconBuffer,
    GraphUp as IconElevation,
    HouseFill as IconHouse,
    Rulers as IconDistance,
    Textarea as IconArea,
    Trash3Fill as IconTrash,
} from "react-bootstrap-icons";
import { mapInteractionEnable, mapInteractionDisable } from "../utils";
import { alertService, elevationService } from "../services";

export function CustomActions() {
    const map = useMap();
    const circleFeatureGroup = L.featureGroup().addTo(map);
    let heightgraph;

    const [bufferCircleRadius, setBufferCircleRadius] = useState(50);
    const [isActionBufferCircleActive, setIsActionBufferCircleActive] =
        useState(false);

    useEffect(() => {
        if (isActionBufferCircleActive === true) {
            // @TODO: ensure that this bind is getting triggered first, so that stopPropagation can work
            map.on("click", (event) => {
                drawCircle(event, bufferCircleRadius);
            });
        }

        return () => {
            map.off("click");
        };
    }, [bufferCircleRadius, isActionBufferCircleActive]);

    useEffect(() => {
        // buttons will be added in the reverse order, first here is last on the map
        const actionDelete = createActionDelete();
        const actionBufferObjects = createActionBufferObjects();
        const actionBufferCircle = createActionBufferCircle();
        const actionHeightgraph = createActionHeightgraph();
        const actionArea = createActionArea();
        const actionPolyline = createActionPolyline();

        heightgraph = L.control.heightgraph({
            expandCallback: (expanded) => {
                if (false === expanded) {
                    heightgraph.remove();

                    // remove heightgraph's line
                    map.eachLayer((layer) => {
                        // ignore non-Geoman layers
                        if (undefined === layer.pm) {
                            return;
                        }

                        // ignore buffers, circles & rectangles
                        if ("Buffer" === layer.options.origin) {
                            return;
                        }

                        // ignore polygons
                        if (undefined !== layer._tooltip) {
                            return;
                        }

                        layer.remove();
                    });
                }
            },
            position: "bottomleft",
            width: 500,
        });

        return () => {
            actionArea.remove();
            actionBufferCircle.remove();
            actionBufferObjects.remove();
            actionDelete.remove();
            actionHeightgraph.remove();
            actionPolyline.remove();
        };
    }, []);

    const addButton = (title, icon, onActive, onInactive) => {
        const iconString = renderToString(icon);
        return L.easyButton({
            states: [
                {
                    stateName: "button-inactive",
                    icon: iconString,
                    title: title,
                    onClick: onInactive,
                },
                {
                    stateName: "button-active",
                    icon: iconString,
                    title: title,
                    onClick: onActive,
                },
            ],
        })
            .setPosition("bottomright")
            .addTo(map);
    };

    const clearDraw = () => {
        map.eachLayer((layer) => {
            if (null !== layer._path && undefined !== layer.pm) {
                layer.remove();
            }
        });
    };

    const createActionArea = () => {
        const elementId = "area-action";
        const onActionAreaBegin = (btn) => {
            initOnActionBegin(btn, elementId);

            // act upon action button click
            map.on("pm:drawstart", ({ workingLayer }) => {
                let lastClickLatLng = null;
                let clickedDistance = 0;
                // store lat/lng and add distance for clicked points
                workingLayer.on("pm:vertexadded", (event) => {
                    const newClickLatLng = event.latlng;
                    if (null !== lastClickLatLng) {
                        clickedDistance +=
                            lastClickLatLng.distanceTo(newClickLatLng);
                    }
                    lastClickLatLng = newClickLatLng;
                });
                // add distance from current mouse position
                map.on("mousemove", (event) => {
                    if (
                        null !== lastClickLatLng &&
                        undefined !== event.target._tooltip
                    ) {
                        const segmentDistance = lastClickLatLng.distanceTo(
                            event.latlng,
                        );
                        const totalDistance = clickedDistance + segmentDistance;
                        event.target.setTooltipContent(
                            getDistanceLabel(totalDistance, segmentDistance),
                        );
                    }
                });
            });

            // act upon polygon done
            map.on("pm:create", (event) => {
                const layer = event.layer;

                // ensure that newly created layers can be removed with Geoman's eraser
                layer.options.pmIgnore = false;
                L.PM.reInitLayer(layer);

                // calculate and show area
                if ("Polygon" === event.shape) {
                    const latLngs = layer.getLatLngs();
                    const area = L.GeometryUtil.geodesicArea(latLngs[0]);
                    const readableArea = L.GeometryUtil.readableArea(
                        area,
                        true,
                        2,
                    );
                    layer
                        .bindTooltip(readableArea, { permanent: true })
                        .openTooltip();
                }
                // disable action to allow for a quick restart
                onActionEnd(button, elementId);
            });

            map.pm.enableDraw("Polygon");
        };

        const button = createButton(
            "Area",
            <IconArea />,
            onActionAreaBegin,
            (btn) => onActionEnd(btn, elementId),
            elementId,
        );
        return button;
    };

    const createActionBufferCircle = () => {
        const elementId = "circle-buffer-action";
        const title = "Circle Buffer";
        const icon = <IconBuffer title={title} />;
        const button = addButton(
            title,
            icon,
            (btn) => {
                initOnActionEnd(btn, elementId);
                setIsActionBufferCircleActive(false);
            },
            (btn) => {
                initOnActionBegin(btn, elementId);
                setIsActionBufferCircleActive(true);
            },
        );

        // add additional logic to button
        const buttonContainer = document.querySelectorAll(
            ".leaflet-bottom.leaflet-right .easy-button-container",
        )[0];
        const container = document.createElement("div");
        container.id = elementId;
        container.className = "leaflet-pm-actions-container d-none";
        const action = document.createElement("div");
        action.className = "leaflet-pm-action";
        const a = document.createElement("a");
        a.className = "pe-2";
        a.textContent = `Radius (m)`;
        action.appendChild(a);
        const input = document.createElement("input");
        L.DomEvent.disableClickPropagation(input);
        input.className = "ps-1";
        input.maxLength = 4;
        input.onkeyup = () => {
            let newRadius = parseInt(input.value, 10);
            if (input.value !== "" && Number.isInteger(newRadius) === false) {
                input.value = bufferCircleRadius;
                newRadius = bufferCircleRadius;
            }

            setBufferCircleRadius(newRadius);
        };
        input.type = "text";
        input.value = bufferCircleRadius;
        action.appendChild(input);
        container.appendChild(action);
        buttonContainer.appendChild(container);

        return button;
    };

    // Fonction complète avec mode édition, déplacement et rotation
    const createActionBufferObjects = () => {
        const elementId = "objects-buffer-action";
        const title = "Buffers";

        // Create the main button
        const button = addButton(
            title,
            <IconHouse title={title} />,
            (btn) => initOnActionEnd(btn, elementId),
            (btn) => initOnActionBegin(btn, elementId),
        );

        // Set up the container for buffer actions
        const buttonContainer = document.getElementsByClassName(
            "easy-button-container",
        )[1];
        const container = document.createElement("div");
        container.id = elementId;
        container.className = "leaflet-pm-actions-container d-none";
        const action = document.createElement("div");
        action.className = "leaflet-pm-action";

        // Variable to track the currently edited element
        let currentEditingElement = null;

        // Add a global click handler to disable edit mode
        map.on("click", function (e) {
            // If a click occurs on the map and not on the element being edited, disable edit mode
            if (currentEditingElement) {
                exitEditMode();
            }
        });

        // Function to exit edit mode
        function exitEditMode() {
            alertService.clear();
            if (currentEditingElement) {
                resetElementStyle(currentEditingElement);
                currentEditingElement = null;
            }

            // Re-enable normal map interactions
            map.dragging.enable();
        }

        // Buffer element configurations
        const bufferElements = [
            {
                color: "#FF7518",
                height: 3,
                title: "JBox",
                width: 10,
            },
            {
                color: "#FF4433",
                height: 10,
                title: "JBox Bail",
                width: 10,
            },
            {
                color: "#FA8072",
                height: 25,
                title: "IECharge Commune",
                width: 10,
            },
            {
                color: "#FFAA33",
                height: 27,
                title: "IECharge Département",
                width: 11,
            },
        ];

        // Create buffer action items
        bufferElements.forEach((element) => {
            const a = document.createElement("a");
            a.className = "d-block";
            a.textContent = element.title;
            a.onclick = () => handleBufferElementClick(element);
            action.appendChild(a);
        });

        container.appendChild(action);
        buttonContainer.appendChild(container);

        // Handle click on a buffer element
        function handleBufferElementClick(element) {
            alertService.info(
                `Click on the map to place the item ${element.title}`,
                {
                    autoClose: false,
                },
            );

            // Disable map interactions
            disableMapInteractions();

            // Variable to track placement state
            let placementStage = "ready"; // 'ready', 'waiting'

            // Function to place the element
            function placeElementHandler(e) {
                if (placementStage === "ready") {
                    placementStage = "waiting";
                } else if (placementStage === "waiting") {
                    // Use the center of the SECOND click as position
                    const center = e.latlng;

                    // Remove the alert
                    alertService.clear();

                    // Reenable map interactions
                    enableMapInteractions();

                    // Create rectangle with fixed size
                    const rectangle = createFixedSizeRectangle(center, element);

                    // Store original dimensions in meters for future reference
                    rectangle._originalSizeInMeters = {
                        width: element.width,
                        height: element.height,
                    };

                    // Fit map to rectangle bounds
                    map.fitBounds(rectangle.getBounds(), {
                        padding: [50, 50],
                        maxZoom: 19,
                    });

                    // Add edit handlers
                    addEditHandlers(rectangle);

                    // Reset state
                    placementStage = "ready";

                    // Remove click handler
                    map.off("click", placeElementHandler);

                    initOnActionEnd(button, elementId);
                }
            }

            // Add click handler
            map.on("click", placeElementHandler);
        }

        // Create rectangle with fixed dimensions
        function createFixedSizeRectangle(center, element) {
            // Calculate corners using physical dimensions in meters
            const widthInMeters = element.width;
            const heightInMeters = element.height;

            // Calculate the four sides of the rectangle based on physical distance
            const northPoint = calculateDestinationPoint(
                center,
                0,
                heightInMeters / 2,
            );
            const southPoint = calculateDestinationPoint(
                center,
                180,
                heightInMeters / 2,
            );
            const eastPoint = calculateDestinationPoint(
                center,
                90,
                widthInMeters / 2,
            );
            const westPoint = calculateDestinationPoint(
                center,
                270,
                widthInMeters / 2,
            );

            // Create the four corners of the rectangle
            const northWest = L.latLng(northPoint.lat, westPoint.lng);
            const northEast = L.latLng(northPoint.lat, eastPoint.lng);
            const southEast = L.latLng(southPoint.lat, eastPoint.lng);
            const southWest = L.latLng(southPoint.lat, westPoint.lng);

            // Create rectangle with additional properties for editing
            return L.rectangle([[northWest, northEast, southEast, southWest]], {
                color: element.color,
                origin: "Buffer",
                pmIgnore: false,
                weight: 2,
                title: element.title,
            }).addTo(map);
        }

        // Function to update rectangle coordinates maintaining fixed size in meters
        function updateRectangleCoordinates(rectangle, center) {
            // Use the original dimensions in meters or current dimensions if not available
            const widthInMeters = rectangle._originalSizeInMeters
                ? rectangle._originalSizeInMeters.width
                : map.distance(
                      rectangle.getBounds().getNorthWest(),
                      rectangle.getBounds().getNorthEast(),
                  );

            const heightInMeters = rectangle._originalSizeInMeters
                ? rectangle._originalSizeInMeters.height
                : map.distance(
                      rectangle.getBounds().getNorthWest(),
                      rectangle.getBounds().getSouthWest(),
                  );

            // Calculate the four sides using physical distance
            const northPoint = calculateDestinationPoint(
                center,
                0,
                heightInMeters / 2,
            );
            const southPoint = calculateDestinationPoint(
                center,
                180,
                heightInMeters / 2,
            );
            const eastPoint = calculateDestinationPoint(
                center,
                90,
                widthInMeters / 2,
            );
            const westPoint = calculateDestinationPoint(
                center,
                270,
                widthInMeters / 2,
            );

            // Create the four corners of the rectangle
            const northWest = L.latLng(northPoint.lat, westPoint.lng);
            const northEast = L.latLng(northPoint.lat, eastPoint.lng);
            const southEast = L.latLng(southPoint.lat, eastPoint.lng);
            const southWest = L.latLng(southPoint.lat, westPoint.lng);

            // Update rectangle coordinates
            rectangle.setLatLngs([
                [northWest, northEast, southEast, southWest],
            ]);
        }

        // Manage map interactions
        function disableMapInteractions() {
            map.dragging.disable();
            map.scrollWheelZoom.disable();
            map.boxZoom.disable();
            map.keyboard.disable();
        }

        function enableMapInteractions() {
            map.dragging.enable();
            map.scrollWheelZoom.enable();
            map.boxZoom.enable();
            map.keyboard.enable();
        }

        // Function to add edit handlers to an element
        function addEditHandlers(element) {
            // Store current rotation angle in the element to preserve it
            if (element.options.rotationAngle === undefined) {
                element.options.rotationAngle = 0;
            }

            // Click handler to activate edit mode
            element.on("click", function (e) {
                // Prevent propagation to avoid the click being captured by the map
                L.DomEvent.stopPropagation(e);

                // If another element is already being edited, reset its style
                if (
                    currentEditingElement &&
                    currentEditingElement !== element
                ) {
                    resetElementStyle(currentEditingElement);
                }

                // Activate edit mode for this element
                currentEditingElement = element;
                alertService.warn(`Edit mode is active`, {
                    autoClose: false,
                });

                // Store original style if not already done
                if (!element._originalStyle) {
                    element._originalStyle = {
                        color: element.options.color,
                        fillColor: element.options.color,
                        weight: 2,
                        fillOpacity: 0.2,
                    };
                }

                // Highlight the element in blue
                element.setStyle({
                    color: "#2196F3",
                    weight: 4,
                    opacity: 1,
                    dashArray: "5, 5",
                    fillOpacity: 0.4,
                    fillColor: "#64B5F6",
                });

                // Store original coordinates to preserve shape
                if (!element._originalLatLngs) {
                    element._originalLatLngs = element
                        .getLatLngs()[0]
                        .map((latlng) => L.latLng(latlng.lat, latlng.lng));

                    // If the element already has a saved rotation angle, apply it
                    if (
                        element.options.rotationAngle &&
                        element.options.rotationAngle !== 0
                    ) {
                        // Get element center
                        const center = element.getBounds().getCenter();

                        // Apply saved rotation
                        rotateElement(
                            element,
                            center,
                            element.options.rotationAngle,
                        );

                        // Update label position if needed
                        if (element._titleLabel) {
                            element._titleLabel.setLatLng(center);
                        }
                    }
                }

                // Add rotation handles to the four corners
                addRotationHandles(element);
            });

            // Handle element movement
            element.on("mousedown", function (e) {
                if (currentEditingElement !== element) return;

                L.DomEvent.stopPropagation(e);
                let isDragging = true;

                // Store initial mouse position
                const startLatLng = map.mouseEventToLatLng(e.originalEvent);

                // Store current center
                const startCenter = element.getBounds().getCenter();

                // Disable map movement
                map.dragging.disable();

                // Change cursor
                if (element._path) {
                    element._path.style.cursor = "grabbing";
                }

                // Handler to track mouse movement
                function moveHandler(moveEvent) {
                    if (!isDragging) return;

                    // Current mouse position
                    const currentLatLng = map.mouseEventToLatLng(
                        moveEvent.originalEvent,
                    );

                    // Calculate latitude and longitude differences
                    const latDiff = currentLatLng.lat - startLatLng.lat;
                    const lngDiff = currentLatLng.lng - startLatLng.lng;

                    // Calculate new center
                    const newCenter = L.latLng(
                        startCenter.lat + latDiff,
                        startCenter.lng + lngDiff,
                    );

                    // Update rectangle with fixed physical size at new center
                    updateRectangleCoordinates(element, newCenter);

                    // Reapply rotation if necessary
                    if (
                        element.options.rotationAngle &&
                        element.options.rotationAngle !== 0
                    ) {
                        rotateElement(
                            element,
                            newCenter,
                            element.options.rotationAngle,
                        );
                    }

                    // Update rotation handles position
                    if (element._rotationHandles) {
                        updateRotationHandlesPositions(element);
                    }

                    // Update label position
                    if (element._titleLabel) {
                        element._titleLabel.setLatLng(newCenter);
                    }
                }

                // Handler to end movement
                function endMoveHandler() {
                    isDragging = false;

                    // Reenable map movement
                    map.dragging.enable();

                    // Restore cursor
                    if (element._path) {
                        element._path.style.cursor = "";
                    }

                    // Remove handlers
                    map.off("mousemove", moveHandler);
                    map.off("mouseup", endMoveHandler);
                }

                // Add handlers
                map.on("mousemove", moveHandler);
                map.on("mouseup", endMoveHandler);
            });
        }

        // Function to add rotation handles
        function addRotationHandles(element) {
            // Remove existing handles if necessary
            removeRotationHandles(element);

            // Create icon for rotation handles
            const rotateIconHtml = `
                <div style="
                    width: 20px;
                    height: 20px;
                    background-color: #1565C0;
                    border: 2px solid white;
                    border-radius: 50%;
                    cursor: grab;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
                ">
                    <svg viewBox="0 0 24 24" width="14" height="14" stroke="white" stroke-width="2" fill="none">
                        <path d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3" stroke="white" stroke-width="2" stroke-linecap="round"/>
                        <path d="M12 8L16 3M16 3L11 3" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            `;

            // Store element center for rotation
            const rotationCenter = element.getBounds().getCenter();

            // Get coordinates of the four corners
            const latLngs = element.getLatLngs()[0];

            // Create array to store handles
            element._rotationHandles = [];

            // Add a handle to each corner
            for (let i = 0; i < latLngs.length; i++) {
                const handle = L.marker(latLngs[i], {
                    icon: L.divIcon({
                        className: "rotation-handle",
                        html: rotateIconHtml,
                        iconSize: [20, 20],
                        iconAnchor: [10, 10],
                    }),
                    draggable: false,
                    interactive: true,
                }).addTo(map);

                handle._cornerIndex = i;

                // Handler to start rotation
                handle.on("mousedown", function (e) {
                    L.DomEvent.stopPropagation(e);

                    // Rotation variables
                    let isRotating = true;
                    let lastAngle = null;

                    // Temporarily disable map movement
                    map.dragging.disable();

                    // Update cursor
                    document.body.style.cursor = "grabbing";

                    // Handle rotation
                    function handleRotation(moveEvent) {
                        if (!isRotating) return;

                        // Current mouse position
                        const currentMousePos = map.mouseEventToLatLng(
                            moveEvent.originalEvent,
                        );

                        // Calculate current angle
                        const currentAngle = calculateAngle(
                            rotationCenter,
                            currentMousePos,
                        );

                        // Initial angle (first time)
                        if (lastAngle === null) {
                            lastAngle = currentAngle;
                            return;
                        }

                        // Calculate angle difference - CHANGE HERE (reversed sign)
                        const angleDiff = lastAngle - currentAngle;

                        // Update element rotation
                        const currentRotation =
                            (element.options.rotationAngle || 0) + angleDiff;
                        element.options.rotationAngle = currentRotation;

                        // Perform rotation
                        rotateElement(element, rotationCenter, currentRotation);

                        // Update rotation handles positions
                        updateRotationHandlesPositions(element);

                        // Update last angle
                        lastAngle = currentAngle;
                    }

                    // Function to end rotation
                    function endRotation() {
                        if (!isRotating) return;

                        isRotating = false;
                        lastAngle = null;

                        // Restore cursor
                        document.body.style.cursor = "";

                        // Reenable map movement
                        map.dragging.enable();

                        // Remove event handlers
                        map.off("mousemove", handleRotation);
                        map.off("mouseup", endRotation);
                    }

                    // Add handlers to track rotation
                    map.on("mousemove", handleRotation);
                    map.on("mouseup", endRotation);
                });

                // Add handle to array
                element._rotationHandles.push(handle);
            }
        }

        // Function to remove rotation handles
        function removeRotationHandles(element) {
            if (
                element._rotationHandles &&
                element._rotationHandles.length > 0
            ) {
                element._rotationHandles.forEach((handle) => {
                    map.removeLayer(handle);
                });
                element._rotationHandles = [];
            }
        }

        // Function to update rotation handles positions
        function updateRotationHandlesPositions(element) {
            if (!element._rotationHandles) return;

            // Get current rectangle coordinates after rotation
            const latLngs = element.getLatLngs()[0];

            // Update position of each handle
            element._rotationHandles.forEach((handle, index) => {
                if (latLngs && latLngs.length > index) {
                    handle.setLatLng(latLngs[index]);
                }
            });
        }

        // Function to reset element style
        function resetElementStyle(element) {
            if (element._originalStyle) {
                element.setStyle({
                    color: element._originalStyle.color,
                    weight: element._originalStyle.weight,
                    opacity: 1,
                    dashArray: null,
                    fillOpacity: element._originalStyle.fillOpacity,
                    fillColor: element._originalStyle.fillColor,
                });
            }

            // Remove informative label
            if (element._titleLabel) {
                map.removeLayer(element._titleLabel);
                delete element._titleLabel;
            }

            // Remove rotation handles
            removeRotationHandles(element);

            // Reset edit mode
            currentEditingElement = null;
            alertService.clear();
        }

        // Helper function to calculate angle between two points
        function calculateAngle(point1, point2) {
            return Math.atan2(point2.lat - point1.lat, point2.lng - point1.lng);
        }

        // Helper function to calculate a point at a given distance and angle
        function calculateDestinationPoint(start, bearing, distance) {
            // Earth radius in meters
            const R = 6371000;

            // Convert to radians
            const lat1 = (start.lat * Math.PI) / 180;
            const lon1 = (start.lng * Math.PI) / 180;
            const brng = (bearing * Math.PI) / 180;

            // Calculate new point
            const lat2 = Math.asin(
                Math.sin(lat1) * Math.cos(distance / R) +
                    Math.cos(lat1) * Math.sin(distance / R) * Math.cos(brng),
            );

            const lon2 =
                lon1 +
                Math.atan2(
                    Math.sin(brng) * Math.sin(distance / R) * Math.cos(lat1),
                    Math.cos(distance / R) - Math.sin(lat1) * Math.sin(lat2),
                );

            // Convert to degrees
            return L.latLng((lat2 * 180) / Math.PI, (lon2 * 180) / Math.PI);
        }

        // Function to perform element rotation
        function rotateElement(element, center, angle) {
            // Ensure we have consistent physical dimensions
            const rectangle = element;
            const currentBounds = rectangle.getBounds();
            const currentCenter = currentBounds.getCenter();

            // Use stored original dimensions or get them from current bounds
            let width, height;

            if (rectangle._originalSizeInMeters) {
                width = rectangle._originalSizeInMeters.width;
                height = rectangle._originalSizeInMeters.height;
            } else {
                // Calculate current width and height in meters (avoid using screen pixels)
                width = map.distance(
                    currentBounds.getNorthWest(),
                    currentBounds.getNorthEast(),
                );
                height = map.distance(
                    currentBounds.getNorthWest(),
                    currentBounds.getSouthWest(),
                );

                // Store for future reference
                rectangle._originalSizeInMeters = { width, height };
            }

            // Calculate rectangle points in consistent physical dimensions
            const halfWidth = width / 2;
            const halfHeight = height / 2;

            // Calculate the four corners in meters from center (before rotation)
            const northPoint = calculateDestinationPoint(center, 0, halfHeight);
            const southPoint = calculateDestinationPoint(
                center,
                180,
                halfHeight,
            );
            const eastPoint = calculateDestinationPoint(center, 90, halfWidth);
            const westPoint = calculateDestinationPoint(center, 270, halfWidth);

            // Create the four corners of the rectangle (before rotation)
            const northWest = L.latLng(northPoint.lat, westPoint.lng);
            const northEast = L.latLng(northPoint.lat, eastPoint.lng);
            const southEast = L.latLng(southPoint.lat, eastPoint.lng);
            const southWest = L.latLng(southPoint.lat, westPoint.lng);

            // Convert to container points for rotation
            const centerPoint = map.latLngToContainerPoint(center);
            const nwPoint = map.latLngToContainerPoint(northWest);
            const nePoint = map.latLngToContainerPoint(northEast);
            const sePoint = map.latLngToContainerPoint(southEast);
            const swPoint = map.latLngToContainerPoint(southWest);

            // Get relative positions to center
            const points = [
                { x: nwPoint.x - centerPoint.x, y: nwPoint.y - centerPoint.y },
                { x: nePoint.x - centerPoint.x, y: nePoint.y - centerPoint.y },
                { x: sePoint.x - centerPoint.x, y: sePoint.y - centerPoint.y },
                { x: swPoint.x - centerPoint.x, y: swPoint.y - centerPoint.y },
            ];

            // Apply rotation to each corner
            const angleRad = angle;
            const cosAngle = Math.cos(angleRad);
            const sinAngle = Math.sin(angleRad);

            const rotatedPoints = points.map((point) => {
                return {
                    x: point.x * cosAngle - point.y * sinAngle,
                    y: point.x * sinAngle + point.y * cosAngle,
                };
            });

            // Convert rotated points back to latLng
            const latLngs = rotatedPoints.map((point) => {
                const pixelPoint = L.point(
                    centerPoint.x + point.x,
                    centerPoint.y + point.y,
                );
                return map.containerPointToLatLng(pixelPoint);
            });

            // Update element coordinates
            element.setLatLngs([latLngs]);
            element.redraw();
        }

        // Modification of click handler for reset
        map.on("click", function (e) {
            // If a click occurs on the map and not on the element being edited, disable edit mode
            if (currentEditingElement) {
                // Check if click is not on the element being edited
                const clickedLayer = e.originalEvent.target;
                if (!currentEditingElement._path.contains(clickedLayer)) {
                    exitEditMode();
                }
            }
        });

        return button;
    };

    const createActionDelete = () => {
        return L.easyButton(
            renderToString(<IconTrash />),
            () => {
                clearDraw();
            },
            "Remove all",
        )
            .setPosition("bottomright")
            .addTo(map);
    };

    const createActionHeightgraph = () => {
        const elementId = "elevation-action";
        const onActionHeightgraphBegin = (btn) => {
            initOnActionBegin(btn, elementId);

            map.pm.enableDraw("Line");
            map.on("pm:create", (event) => {
                const layer = event.layer;

                // ensure that newly created layers can be removed with Geoman's eraser
                layer.options.pmIgnore = false;
                L.PM.reInitLayer(layer);

                if ("Line" === event.shape) {
                    // convert clicked points to geodesic line, i.e. extrapolating their lat/lng to a mappable line
                    const coordinates = new L.geodesic(
                        layer.getLatLngs(),
                    ).getLatLngs()[0];

                    drawHeightgraph(coordinates);
                    onActionHeightgraphEnd(btn);
                }
            });
        };
        const onActionHeightgraphEnd = (btn) => {
            initOnActionEnd(btn, elementId);

            map.pm.disableDraw();
            map.off("pm:drawend");
        };

        return createButton(
            "Elevation",
            <IconElevation />,
            onActionHeightgraphBegin,
            onActionHeightgraphEnd,
            elementId,
        );
    };

    const createActionPolyline = () => {
        const elementId = "polyline-action";
        const onActionPolylineBegin = (btn) => {
            initOnActionBegin(btn, elementId);

            let clickedDistance;

            // act upon action button click
            map.on("pm:drawstart", ({ workingLayer }) => {
                let lastClickLatLng = null;
                clickedDistance = 0;
                // store lat/lng and add distance for clicked points
                workingLayer.on("pm:vertexadded", (event) => {
                    const newClickLatLng = event.latlng;
                    if (null !== lastClickLatLng) {
                        clickedDistance +=
                            lastClickLatLng.distanceTo(newClickLatLng);
                    }
                    lastClickLatLng = newClickLatLng;
                });
                // add distance from current mouse position
                map.on("mousemove", (event) => {
                    if (
                        null !== lastClickLatLng &&
                        undefined !== event.target._tooltip
                    ) {
                        const segmentDistance = lastClickLatLng.distanceTo(
                            event.latlng,
                        );
                        const totalDistance = clickedDistance + segmentDistance;
                        event.target._tooltip._contentNode.classList.add(
                            "tooltip-distance",
                        ); // ugly but does the job, not sure where that tooltip is initialized
                        event.target.setTooltipContent(
                            getDistanceLabel(totalDistance, segmentDistance),
                        );
                    }
                });
            });

            // act upon polygon done
            map.on("pm:create", (event) => {
                const layer = event.layer;

                // ensure that newly created layers can be removed with Geoman's eraser
                layer.options.pmIgnore = false;
                L.PM.reInitLayer(layer);

                if ("Line" === event.shape) {
                    layer
                        .bindTooltip(getDistanceLabel(clickedDistance), {
                            className: "tooltip-distance",
                            permanent: true,
                        })
                        .openTooltip();
                }
                // disable action to allow for a quick restart
                onActionEnd(button, elementId);
            });

            map.pm.enableDraw("Line");
        };

        const button = createButton(
            "Distance",
            <IconDistance />,
            onActionPolylineBegin,
            (btn) => onActionEnd(btn, elementId),
            elementId,
        );
        return button;
    };

    const createButton = (
        title,
        icon,
        onActionBegin,
        onActionEnd,
        elementId,
    ) => {
        const button = addButton(title, icon, onActionEnd, onActionBegin);
        const buttonContainer = document.getElementsByClassName(
            "easy-button-container",
        )[1];
        const container = document.createElement("div");
        container.id = elementId;
        container.className = "leaflet-pm-actions-container d-none";
        buttonContainer.appendChild(container);
        return button;
    };

    const drawCircle = (event, radius) => {
        L.circle(event.latlng, {
            origin: "Buffer",
            pmIgnore: false,
            radius: 1,
        }).addTo(circleFeatureGroup); // this is a hack to mark the center of the circle... through another circle :/
        L.circle(event.latlng, {
            origin: "Buffer",
            pmIgnore: false,
            radius: radius,
        }).addTo(circleFeatureGroup);
        circleFeatureGroup.bringToFront();
    };

    const drawHeightgraph = (latLngs) => {
        elevationService.get(latLngs).then((response) => {
            if (response === null) {
                alertService.error("Problems with obtaining elevation data");
                return;
            }

            const elevations = [];
            let distance = 0; // in m, needed for slope calculation
            let prevCoordinate = null;

            const coordinates = response.map((data) => {
                let coordinate = [data.location.lat, data.location.lng];
                if (prevCoordinate !== null) {
                    distance += map.distance(prevCoordinate, coordinate);
                }
                prevCoordinate = coordinate;

                elevations.push(data.elevation);
                return [
                    data.location.lng,
                    data.location.lat,
                    Number(data.elevation.toFixed(2)),
                ];
            });

            let elevationTotal = 0;
            elevations.forEach((elevation, index) => {
                if (index !== elevations.length - 1) {
                    elevationTotal += Math.abs(
                        elevations[index + 1] - elevations[index],
                    );
                }
            });

            const slopePercentage = (elevationTotal / distance) * 100;
            const data = [
                {
                    type: "FeatureCollection",
                    features: [
                        {
                            type: "Feature",
                            geometry: {
                                type: "LineString",
                                coordinates: coordinates,
                            },
                            properties: {
                                attributeType: "",
                            },
                        },
                    ],
                    properties: {
                        Creator: "Google Elevation API",
                        records: 1,
                        summary: `Slope Percentage: ${slopePercentage.toFixed(
                            2,
                        )}%`,
                    },
                },
            ];

            heightgraph.addTo(map);
            heightgraph.addData(data);

            // bottom left align
            const panel = document.getElementById("panel");
            const left =
                true === panel.classList.contains("hiding")
                    ? 0
                    : panel.offsetWidth;
            document.querySelector(".heightgraph").style.left = `${left}px`;
        });
    };

    const getDistanceLabel = (totalDistance, segmentDistance = null) => {
        let unit = "m";
        if (1000 < totalDistance) {
            unit = "km";
            totalDistance /= 1000;
            if (null !== segmentDistance) {
                segmentDistance /= 1000;
            }
        }

        const segmentDistanceString =
            null !== segmentDistance
                ? `<div class="polyline-measure-tooltip-difference">+${segmentDistance.toFixed(
                      2,
                  )} ${unit}</div>`
                : "";
        const totalDistanceString = `<div class="polyline-measure-tooltip-total">${totalDistance.toFixed(
            2,
        )} ${unit}</div>`;
        return segmentDistanceString + totalDistanceString;
    };

    const initOnActionBegin = (btn, elementId) => {
        mapInteractionDisable(map);
        btn.state("button-active");
        const container = document.getElementById(elementId);
        container.classList.remove("d-none");
    };

    const initOnActionEnd = (btn, elementId) => {
        mapInteractionEnable(map);
        btn.state("button-inactive");
        const container = document.getElementById(elementId);
        container.classList.add("d-none");
    };

    const onActionEnd = (btn, elementId) => {
        initOnActionEnd(btn, elementId);

        map.pm.disableDraw();
        map.off("mousemove");
        map.off("pm:create");
        map.off("pm:drawstart");
        map.off("pm:vertexadded");
    };
}
