import L from "leaflet";
import { renderToString } from "react-dom/server";
import {
    BoxFill as IconBoxFill,
    BrightnessHigh as IconBrightnessHigh,
    PinAngleFill as IconPinAngleFill,
} from "react-bootstrap-icons";

export const markerIconInstallation = (color) => {
    return new L.DivIcon({
        className: "leaflet-marker-icon-custom leaflet-marker-icon-zone",
        iconAnchor: [24, 24],
        iconSize: [48, 48],
        html: renderToString(<IconBoxFill fill={color} size={48} />),
    });
};

export const markerIconPin = () => {
    return new L.DivIcon({
        className: "leaflet-marker-icon-custom leaflet-marker-icon-pin",
        iconAnchor: [0, 48],
        iconSize: [48, 48],
        html: renderToString(
            <IconPinAngleFill
                fill="#FE7569"
                size={48}
                style={{
                    stroke: "#FFF",
                    strokeOpacity: ".75",
                    strokeWidth: ".5px",
                }}
            />,
        ),
        popupAnchor: [24, -24], // does not behave the same when a default icon and a React Leaflet marker icon
    });
};

export const markerIconSubstation = (color) => {
    return new L.DivIcon({
        className: "leaflet-marker-icon-custom leaflet-marker-icon-zone",
        iconAnchor: [24, 24],
        iconSize: [48, 48],
        html: renderToString(<IconBrightnessHigh fill={color} size={48} />),
    });
};
