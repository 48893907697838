import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { initAuth } from "./utils";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { MapAlert } from "./components";
import { Home } from "./pages";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

const Pages = () => {
    return (
        <Routes>
            <Route path="*" element={<Home />} />
        </Routes>
    );
};

function App({ instance }) {
    useEffect(() => {
        initAuth(instance);
    });

    const request = {
        ...loginRequest,
        account: instance.getActiveAccount() || {},
    };

    return (
        <MsalProvider instance={instance}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={request}
            >
                <MapAlert />
                <Pages />
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
}

export default App;
