import { fetchWithMsal, handleResponse } from "../utils";

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/layers`;

export const layerService = {
    addElements,
    deleteElement,
    filter,
    getAll,
};

function addElements(layerId, data) {
    return fetchWithMsal(`${baseUrl}/${layerId}/elements`, {
        body: JSON.stringify(data),
        method: "POST",
    })
        .then(handleResponse)
        .catch((error) => {
            console.error(
                `layerService.addElements: Layer ID: ${layerId} ; data: ${data}`,
                error,
            );
            return [];
        });
}

function deleteElement(elementId) {
    return fetchWithMsal(`${baseUrl}/elements/${elementId}`, {
        method: "DELETE",
    })
        .then(handleResponse)
        .catch((error) => {
            console.error(
                `layerService.deleteElement: elementId: ${deleteElement}`,
                error,
            );
            return [];
        });
}

function filter(layerId, column, condition, value) {
    return fetchWithMsal(
        `${baseUrl}/${layerId}/filter?&filter_col=${column}&filter_cond=${condition}&filter_value=${value}`,
    )
        .then(handleResponse)
        .catch((error) => {
            console.error(
                `layerService.filter: Layer ID: ${layerId} ; Column: ${column} ; Condition: ${condition} ; Value: ${value}`,
                error,
            );
            return [];
        });
}

async function getAll() {
    return await fetchWithMsal(
        `${baseUrl}/?page=1&size=${process.env.REACT_APP_API_MAX_PAGE_SIZE}`,
    )
        .then(handleResponse)
        .then((data) => {
            const items = data.items.filter(
                (item) =>
                    "properties" in item === false ||
                    item.properties.hidden !== true,
            );
            console.info(
                `${data.items.length - items.length} layers hidden based on their properties.`,
            );
            return items;
        })
        .catch((error) => {
            console.error(`layerService.getAll`, error);
            return null;
        });
}
