import { useEffect, useId, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import ReactSlider from "react-slider";
import { copyDeep } from "../utils";

/**
 * New layer filter form field (search endpoint). There are three field types: CHOICES, FLAG, NUM.
 *  - CHOICES is an array of options.
 *  - FLAG is a single boolean value.
 *  - NUM is an array with a >= and <= value, whereas both can be NULL (in this case the slider shows min or max instead).
 *
 * @param field             the field with all its properties to make the filter function
 * @param isDisabled        whether the field is disabled upon load
 * @param updateValue       does what it needs to do with the updated value
 * @param titleChoice       the form group's title, only used for choices
 */
export function FilterLineFormField({
    field,
    isDisabled,
    updateValue,
    titleChoice,
}) {
    const id = useId();
    const isFloat = false === Number.isInteger(field.step); // NUM only: whether to fake a float slider

    // Holds the slider's visible values and thus never NULL (infinite except for this init), to memorize null values use isMaxNull, isMinNull
    const [sliderData, setSliderData] = useState({
        max: null,
        isMaxNull: true,
        min: null,
        isMinNull: true,
        step: null,
        value: [null, null],
    });

    // holds the non-slider values
    const [value, setValue] = useState(
        field.value || field.defaultValue || null,
    );

    // NUM only: init range slider data
    useEffect(() => {
        if ("NUM" === field.type) {
            let sliderDataMax = copyDeep(field.max);
            let sliderDataMin = copyDeep(field.min);
            let sliderDataStep = copyDeep(field.step);
            let sliderDataValue = copyDeep(field.value);

            // init NULL values to min/max
            if (null === field.value[0]) sliderDataValue[0] = sliderDataMin;
            if (null === field.value[1]) sliderDataValue[1] = sliderDataMax;

            // ReactSlider does not support decimals, hence multiply and divide accordingly
            if (true === isFloat) {
                sliderDataMax *= 10;
                sliderDataMin *= 10;
                sliderDataStep *= 10;
                sliderDataValue[0] *= 10;
                sliderDataValue[1] *= 10;
            }

            setSliderData({
                max: sliderDataMax,
                isMaxNull: null === field.value[1],
                min: sliderDataMin,
                isMinNull: null === field.value[0],
                step: sliderDataStep,
                value: sliderDataValue,
            });

            setValue(field.value);
        }
    }, [field]);

    // NUM only: receives scaled values from slider or reset functions
    const setValues = (values, isMinNull, isMaxNull) => {
        const localValues = values;

        if (true === isFloat) localValues[1] /= 10;
        if (true === isFloat) localValues[0] /= 10;
        if (true === isMaxNull) localValues[1] = null;
        if (true === isMinNull) localValues[0] = null;

        setValue(localValues);
        updateValue(localValues);
    };

    const switchOnChange = (localValue) => {
        if (false === localValue) {
            localValue = "";
        }
        setValue(localValue);
        updateValue(localValue);
    };

    switch (field.type) {
        case "CHOICE":
            return (
                <>
                    <div className="mb-1">{titleChoice}</div>
                    {[...field.choices].sort().map((choice, key) => (
                        <Form.Check
                            checked={true === value.includes(choice)}
                            className="fst-italic"
                            disabled={true === isDisabled}
                            id={`${field.field}-${choice}-${id}`}
                            key={key}
                            label={choice}
                            onChange={() => {
                                // value is an array of values
                                let localValue;
                                if (true === value.includes(choice)) {
                                    // remove
                                    localValue = value.filter(
                                        (v) => choice !== v,
                                    );
                                } else {
                                    // add
                                    localValue = [...value, choice];
                                }

                                setValue(localValue);
                                updateValue(localValue);
                            }}
                            type="switch"
                        />
                    ))}
                </>
            );

        case "FLAG":
            return (
                <Form.Switch
                    checked={true === value}
                    className="fst-italic"
                    disabled={true === isDisabled}
                    label={
                        <span onClick={() => switchOnChange(!value)}>
                            {field.name}
                        </span>
                    }
                    onChange={(event) =>
                        switchOnChange(event.currentTarget.checked)
                    }
                />
            );

        case "NUM":
            return (
                <InputGroup className="my-2" size="sm">
                    <Form.Label className="mb-0" size="sm">
                        {true === isDisabled ? (
                            field.name
                        ) : (
                            <>
                                {field.name}
                                {"" !== value && (
                                    <span className="fw-bold ps-1">
                                        {null !== value[0] ? (
                                            <>
                                                {true === isFloat
                                                    ? value[0].toFixed(1)
                                                    : value[0]}
                                                <a
                                                    className="ms-1"
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        // reset min value
                                                        setValues(
                                                            [
                                                                sliderData.min,
                                                                sliderData
                                                                    .value[1],
                                                            ],
                                                            true,
                                                            sliderData.isMaxNull,
                                                        );
                                                    }}
                                                    role="button"
                                                >
                                                    clear
                                                </a>
                                            </>
                                        ) : (
                                            <>-∞</>
                                        )}
                                        <span className="px-1">→</span>
                                        {null !== value[1] ? (
                                            <>
                                                {true === isFloat
                                                    ? value[1].toFixed(1)
                                                    : value[1]}
                                                <a
                                                    className="ms-1"
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        // reset max value
                                                        setValues(
                                                            [
                                                                sliderData
                                                                    .value[0],
                                                                sliderData.max,
                                                            ],
                                                            sliderData.isMinNull,
                                                            true,
                                                        );
                                                    }}
                                                    role="button"
                                                >
                                                    clear
                                                </a>
                                            </>
                                        ) : (
                                            <>∞</>
                                        )}
                                    </span>
                                )}
                            </>
                        )}
                    </Form.Label>
                    <ReactSlider
                        ariaLabel={["Min", "Max"]}
                        disabled={true === isDisabled}
                        onChange={(values) => {
                            setValues(
                                values,
                                sliderData.value[0] !== values[0]
                                    ? false
                                    : sliderData.isMinNull,
                                sliderData.value[1] !== values[1]
                                    ? false
                                    : sliderData.isMaxNull,
                            );
                        }}
                        max={sliderData.max}
                        min={sliderData.min}
                        step={sliderData.step}
                        value={sliderData.value}
                    />
                </InputGroup>
            );
    }
}
